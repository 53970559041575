import { Box, Text } from 'grommet'
import React from 'react'
import anylogger from 'anylogger'

const log = anylogger('ErrorBoundary')

class ErrorBoundaryComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    log.error('error: ', error)
    return { error: true, errorInfo: error }
  }

  componentDidCatch(error, errorInfo) {
    if (
      error.toString().includes('ChunkLoadError') ||
      error.toString().includes('Error: Loading CSS chunk')
    ) {
      window.location.reload(true)
      return
    }
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.error) {
      // Error path
      return (
        <Box pad="large" align="center">
          <Text>Something went wrong.</Text>
          <Box style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
          </Box>
          <Box style={{ whiteSpace: 'pre-wrap' }}>
            Where it occured: {this.state.errorInfo.componentStack}
          </Box>
        </Box>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}

export default ErrorBoundaryComponent
