// @flow

import React from 'react'

type Props = {
  width: string,
  height: string,
  rotate: boolean,
  noxMainColor: string,
  noxColor: string,
}

class NoxAnimation extends React.PureComponent<Props> {
  static defaultProps = {
    width: '220',
    height: '220',
    rotate: true,
    noxMainColor: '#e21d42',
    noxColor: '#e21d4270',
  }

  render() {
    const { width, height, rotate, noxMainColor, noxColor } = this.props
    return (
      <svg
        viewBox="0 0 220 220"
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <title>Layer 1</title>
          <g stroke="null" id="svg_1">
            <path
              stroke="null"
              className={`${rotate ? 'noxpath1' : ''}`}
              fill={noxMainColor}
              d="m187.74371,168.99896c-3.55994,-3.55683 -8.68781,-4.57586 -13.15893,-3.08099l-17.91277,-17.89715c8.69434,-10.48581 13.92659,-23.93746 13.92659,-38.58631s-5.23226,-28.1005 -13.92659,-38.58631l17.88015,-17.86456c4.47112,1.49487 9.599,0.47584 13.15893,-3.08099c5.01479,-5.01042 5.01479,-13.13442 0,-18.14484c-5.01479,-5.01042 -13.14589,-5.01042 -18.16068,0c-3.55994,3.55683 -4.57986,8.68024 -3.08368,13.14746l-17.88015,17.86456c-10.49496,-8.68675 -23.95835,-13.91445 -38.61998,-13.91445c-31.50446,0 -57.46351,24.13301 -60.35365,54.86691l-25.2849,0c-2.10508,-4.21518 -6.45007,-7.11801 -11.48661,-7.11801c-7.0916,0 -12.84143,5.74482 -12.84143,12.83024c0,7.08542 5.74983,12.83024 12.84143,12.83024c5.03436,0 9.38153,-2.90283 11.48661,-7.11801l25.2849,0c2.89014,30.7339 28.84919,54.86691 60.35365,54.86691c14.66163,0 28.12502,-5.2277 38.61998,-13.91445l17.91277,17.89715c-1.49617,4.46723 -0.47625,9.59063 3.08368,13.14746c5.01479,5.01042 13.14589,5.01042 18.16068,0c5.01479,-5.01042 5.01479,-13.13442 0,-18.14484l0,-0.00002zm-77.77711,-10.52492c-27.06378,0 -49.08233,-21.99935 -49.08233,-49.03953s22.01855,-49.03953 49.08233,-49.03953s49.08233,21.99935 49.08233,49.03953s-22.01855,49.03953 -49.08233,49.03953z"
            />
            <path
              stroke="null"
              className={`${rotate ? 'noxpath2' : ''}`}
              fill={noxColor}
              d="m122.79063,206.54017c0,-5.02997 -2.90536,-9.37335 -7.12422,-11.4766l0,-25.30848c13.56995,-1.27325 26.78977,-7.08542 37.15643,-17.44521s16.18608,-23.56592 17.46043,-37.12403l25.2849,0c2.10508,4.21518 6.45007,7.11801 11.48661,7.11801c7.0916,0 12.84143,-5.74482 12.84143,-12.83024c0,-7.08542 -5.74983,-12.83024 -12.84143,-12.83024c-5.03436,0 -9.38153,2.90283 -11.48661,7.11801l-25.2849,0c-1.27436,-13.55812 -7.0916,-26.76642 -17.46043,-37.12403c-22.27733,-22.25791 -57.7136,-23.53333 -81.50885,-3.84364l-17.87797,-17.86238c1.49617,-4.46723 0.47625,-9.59063 -3.08368,-13.14746c-5.01479,-5.01042 -13.14589,-5.01042 -18.16068,0c-5.01479,5.01042 -5.01479,13.13442 0,18.14484c3.55994,3.55683 8.68781,4.57586 13.15893,3.08099l17.87797,17.86238c-19.70687,23.7745 -18.43034,59.17987 3.84699,81.43778c10.36883,10.35979 23.58648,16.17196 37.15643,17.44521l0,25.30848c-4.21886,2.10325 -7.12422,6.44445 -7.12422,11.4766c0,7.08542 5.74983,12.83024 12.84143,12.83024c7.0916,-0.00217 12.84143,-5.74482 12.84143,-12.83024l0.00001,0.00001zm-47.54918,-62.39123c-19.13711,-19.12042 -19.13711,-50.23238 0,-69.3528s50.27622,-19.12042 69.41333,0s19.13711,50.23238 0,69.3528s-50.27405,19.12042 -69.41333,0z"
              id="svg_3"
            />
            <polygon
              stroke="null"
              fill={noxMainColor}
              points="120.67903900146484,109.48882293701172 119.03063201904297,107.15309143066406 112.44136810302734,97.80145263671875 110.7951431274414,95.46355438232422 109.14674377441406,93.12565612792969 107.5005111694336,90.78775024414062 100.88734436035156,81.4035415649414 98.37342071533203,81.4035415649414 81.88941192626953,81.4035415649414 98.37342071533203,104.53489685058594 100.21101379394531,107.15309143066406 101.85289001464844,109.48882293701172 103.4947738647461,111.82672119140625 105.13664245605469,114.16463470458984 111.70633697509766,123.5162582397461 113.35039520263672,125.85416412353516 114.99225616455078,128.19207000732422 116.63414764404297,130.52996826171875 121.56194305419922,137.543701171875 138.0437774658203,137.543701171875 138.0437774658203,134.39971923828125 121.56194305419922,110.74251556396484 "
              id="svg_4"
            />
            <polygon
              stroke="null"
              fill={noxMainColor}
              points="81.88941192626953,90.78775024414062 81.88941192626953,93.12565612792969 81.88941192626953,95.46355438232422 81.88941192626953,97.80145263671875 81.88941192626953,104.53489685058594 81.88941192626953,107.15309143066406 81.88941192626953,109.48882293701172 81.88941192626953,111.82672119140625 81.88941192626953,114.16463470458984 81.88941192626953,123.5162582397461 81.88941192626953,125.85416412353516 81.88941192626953,128.19207000732422 81.88941192626953,130.52996826171875 81.88941192626953,137.543701171875 98.37342071533203,137.543701171875 98.37342071533203,130.52996826171875 98.37342071533203,128.19207000732422 98.37342071533203,125.85416412353516 98.37342071533203,123.5162582397461 98.37342071533203,114.16463470458984 98.37342071533203,111.82672119140625 98.37342071533203,109.48882293701172 81.88941192626953,81.4035415649414 "
              id="svg_5"
            />
            <polygon
              stroke="null"
              fill={noxMainColor}
              points="121.56194305419922,81.4035415649414 121.56194305419922,90.78775024414062 121.56194305419922,93.12565612792969 121.56194305419922,95.46355438232422 121.56194305419922,97.80145263671875 121.56194305419922,106.54253387451172 138.0437774658203,134.39971923828125 138.0437774658203,130.52996826171875 138.0437774658203,128.19207000732422 138.0437774658203,125.85416412353516 138.0437774658203,123.5162582397461 138.0437774658203,114.16463470458984 138.0437774658203,111.82672119140625 138.0437774658203,109.48882293701172 138.0437774658203,107.15309143066406 138.0437774658203,97.80145263671875 138.0437774658203,95.46355438232422 138.0437774658203,93.12565612792969 138.0437774658203,90.78775024414062 138.0437774658203,81.4035415649414 "
              id="svg_6"
            />
          </g>
        </g>
      </svg>
    )
  }
}

export default NoxAnimation
