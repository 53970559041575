import { FETCH_PROFILE, SESSION_LOGOUT } from '../actions'

const userState = {
  tenantHost: false,
  email: '',
  id: '',
  name: '',
  nickname: '',
  picture: '',
  preferences: null,
  tenantId: '',
  tenantName: '',
}

export default function user(state = userState, action) {
  switch (action.type) {
    case FETCH_PROFILE:
      return { ...state, ...action.user } || state
    case SESSION_LOGOUT:
      return {}
    default:
      return state
  }
}
