import { Box, Heading, Layer } from 'grommet'

import NoxAnimation from './NoxAnimation'
import React from 'react'

function LoadingIndicator(props) {
  if (!props.loading) {
    return null
  }

  if (props.full) {
    return (
      <Layer style={{ zIndex: '1000' }} animation={false} full={props.callback}>
        <Box
          border={false}
          direction="column"
          fill
          className="bg-loading"
          background={props.callback ? 'white' : ''}
          pad="xsmall"
          justify="center"
          alignContent="center"
          basis="2/3"
        >
          <Box justify="center" align="center" pad="none">
            {props.message && (
              <Heading level="2" color="noxred">
                {props.message}
              </Heading>
            )}
            <NoxAnimation width={props.width} height={props.width} />
          </Box>
        </Box>
        <Box basis="1/3" />
      </Layer>
    )
  } else {
    return (
      <Box alignSelf="center" responsive={false}>
        <Box height="xsmall" />
        <NoxAnimation width="150" height="150" />
      </Box>
    )
  }
}

LoadingIndicator.defaultProps = {
  loading: true,
  full: true,
  width: 150,
  callback: false,
}
export default React.memo(LoadingIndicator)
