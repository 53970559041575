import { Capacitor } from '@capacitor/core'
import { KEYS } from './appsettings/constants'
import anylogger from 'anylogger'
// appsync and cognito details are output by terraform

const log = anylogger('StaticConfig')

export const prodDomain = 'app.identivnox.com'
const devDomain = 'dev.identivnox.com'

const configs = {
  [prodDomain]: {
    appsync: {
      region: 'us-west-2',
      url:
        'https://ba74fl33ybb7poib5jao5ojjaa.appsync-api.us-west-2.amazonaws.com/graphql',
    },
    auth0: {
      domain: `login.${prodDomain}`,
      clientID: 'lgEYzDnm6o35TnGEyA8GpeqRijvcETnG',
      name: 'IdentivNox',
      universalLink: `https://${prodDomain}`,
    },
    cognito: {
      region: 'us-west-2',
      identityPoolId: 'us-west-2:eca19607-9530-4f58-84b7-cd63bdb56db8',
      accountId: '259239618679',
    },
    s3: {
      bucket: 'nox-prod-uploads',
      region: 'us-west-2',
    },
  },
  [devDomain]: {
    appsync: {
      region: 'us-west-2',
      url:
        'https://a4wya3rfnve4vkom4weubjoafq.appsync-api.us-west-2.amazonaws.com/graphql',
    },
    auth0: {
      domain: 'identiv-noxdev.auth0.com',
      clientID: '0tEHIA6IA4ZTOuoQgMCH835oZ0kX8phx',
      name: 'IdentivNox',
      universalLink: `https://${devDomain}`,
    },
    cognito: {
      region: 'us-west-2',
      identityPoolId: 'us-west-2:f6110475-d442-4c04-94cd-b2fc7b5a665d',
      accountId: '624472603577',
    },
    s3: {
      bucket: 'nox-dev-uploads',
      region: 'us-west-2',
    },
  },
  localhost: {
    appsync: {
      region: 'us-west-2',
      url:
        'https://jgurpa7xonhbpkkaec73ton7si.appsync-api.us-west-2.amazonaws.com/graphql',
    },
    auth0: {
      domain: 'identiv-noxphuong.auth0.com',
      clientID: 'IipJ0X4Zt53S42ZOMu2XWY69PPrnBjxN',
      name: 'IdentivNox',
      universalLink: `https://${devDomain}`,
    },
    cognito: {
      region: 'us-west-2',
      identityPoolId: 'us-west-2:a64f21c5-e264-4b42-bb2c-63b2e85f0ee0',
      accountId: '624472603577',
    },
    s3: {
      bucket: 'nox-phuong-uploads',
      region: 'us-west-2',
    },
  },
}

export function getNodeEnv() {
  if (Capacitor.isNative) {
    const appType = localStorage.getItem(KEYS.appType) || prodDomain
    if (appType === prodDomain) {
      return 'prod'
    }
    return 'dev'
  } else {
    if (process.env.NODE_ENV === 'production') {
      if (window.location.hostname === prodDomain) {
        return 'prod'
      }

      if (window.location.hostname === devDomain) {
        return 'dev'
      }
    }
  }

  return 'localhost'
}

export default location => {
  if (Capacitor.isNative) {
    let appType = localStorage.getItem(KEYS.appType) || prodDomain
    log('appType: ', appType)
    return configs[appType]
  }

  let key = location ? location : 'localhost'
  if (window.location.port === '5000') {
    key = devDomain
  }
  log('key: ', key)
  if (
    key.includes('ngrok.io') ||
    key.includes('10.0.2.2') ||
    key.includes('127.0.0.1')
  ) {
    //debug on Android 10.0.2.2
    key = 'localhost'
  }
  return configs[key]
}
