import { FETCH_SETTING, UPDATE_SETTING } from '../actions'

export default function setting(setting = {}, action) {
  switch (action.type) {
    case FETCH_SETTING:
    case UPDATE_SETTING:
      return { ...setting, ...action.setting }
    default:
      return setting
  }
}
