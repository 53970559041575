import Auth from '../auth/Auth'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import anylogger from 'anylogger'
import history from '../components/history'

const log = anylogger('PushNotificationService')

let registerActionPerformed = false
export default class PushNotificationService {
  initPushNotification(isNeedRegisterWithSNS = false) {
    if (Capacitor.isNative) {
      this._registerPushNotification(isNeedRegisterWithSNS)
      registerActionPerformed = true
    }
  }

  _registerPushNotification(isNeedRegisterWithSNS) {
    PushNotifications.requestPermissions().then(() => {
      PushNotifications.register()
        .then(() => {
          log('---> Application registers for push notifications successfully!')
        })
        .catch(() => {
          // Show some error
          log.error("---> Application can't register for push notifications!")
        })
    })

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', token => {
      if (token !== undefined && isNeedRegisterWithSNS) {
        Auth.getInstance().registerWithSNS(token.value)
      }
    })

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', _error => {
      log(
        '---> PushNotifications --> Error on registration: ' +
          JSON.stringify(_error),
      )
    })

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', notification => {
      log(
        '---> PushNotifications --> Push received: ' +
          JSON.stringify(notification),
      )
      // this._handlePushReceived(notification)
    })

    // Method called when tapping on a notification
    this._registerClickOnPushNotification()
  }

  _registerClickOnPushNotification() {
    if (Capacitor.isNative && !registerActionPerformed) {
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        notification => {
          this._handlePushAction(notification)
        },
      )
    }
  }

  _handlePushAction(notification) {
    if (notification) {
      if (notification.actionId === 'tap') {
        const notif = notification.notification
        if (notif && notif.data) {
          const platform = Capacitor.getPlatform()
          let eventUuid = ''
          if (platform === 'android') {
            eventUuid = notif.data.uuid
          } else {
            eventUuid = notif.data.data.uuid
          }
          const eventUrl = `/events/view/${eventUuid}`
          history.push(eventUrl)
        }
      }
    }
  }

  _handlePushReceived(_notification) {
    /*if (_notification && _notification.data) {
      const eventUuid = _notification.data.uuid
      const eventUrl = `/events/view/${eventUuid}`
      if (window.confirm('Go to new event ?')) {
        let handlePushReceived = this._handlePushReceivedEvent.bind(
          this,
          121,
          eventUrl,
        )
        handlePushReceived()
      }
    }*/
  }

  _handlePushReceivedEvent(key, eventUrl) {
    history.push(eventUrl)
  }

  async unregisterPushNotification() {
    if (Capacitor.isNative) {
      // Unregister Push Local.
      PushNotifications.removeAllListeners()
      if (!Auth.getInstance().checkExpiredAuthentication()) {
        await Auth.getInstance().unregisterWithSNS()
      }
    }
  }
}
