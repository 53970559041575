import { getMobileLogoutUri, getRedirectUri } from '../auth/Auth'

import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { getSerialFromQRCode } from '../components/pages/Util'
import history from '../components/history'

export default class AppMobileService {
  handleNavigation() {
    if (Capacitor.isNative) {
      App.addListener('appUrlOpen', data => {
        let url = data.url
        this._handleDeepLink(url)
      })
    }
  }

  _handleDeepLink(url) {
    if (url) {
      let _getRedirectUri = getRedirectUri()
      let _getMobileLogoutUri = getMobileLogoutUri()

      if (url.includes(_getRedirectUri)) {
        let _page = url.replace(_getRedirectUri, '')
        _page = `/callback${_page}`

        // handle auth-0 redirect callback
        history.push(_page)
      } else if (url.includes(_getMobileLogoutUri)) {
        history.push('/login')
      } else if (url.includes('serial')) {
        // deeplink serial
        let _serialNumber = getSerialFromQRCode(url)
        let _path = '/config/doors/serial'
        history.push({
          pathname: _path,
          state: {
            serial: _serialNumber,
          },
        })
      }
    }
  }
}
