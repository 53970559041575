import * as Sentry from '@sentry/react'

import Auth, { getToken } from '../auth/Auth'
import { Box, Button, Grommet } from 'grommet'
import CONSTANTS, { DEFAULT_HOME_PAGE, KEYS } from '../appsettings/constants'
import React, { Component, Suspense } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'

import { Auth0Context } from '../auth/auth0-context'
import { Capacitor } from '@capacitor/core'
import { Close } from 'grommet-icons'
import LoadingIndicator from '../components/elements/Loading'
import LoginCallBackComponent from '../screens/LoginCallBack'
import anylogger from 'anylogger'
import { connect } from 'react-redux'
import history from './history'
import { noxtheme } from '../../theme'
import { subscribeAlarmObject } from '../service/Request'

const log = anylogger('Main')

// https://goenning.net/2018/11/16/how-to-retry-dynamic-import-with-react-lazy/
const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }
          // Passing on "reject" is the important part
          retry(fn, interval, retriesLeft - 1).then(resolve, reject)
        }, interval)
      })
  })
}

const LoginComponent = React.lazy(() => retry(() => import('../screens/Login')))
const WelcomeComponent = React.lazy(() =>
  retry(() => import('../screens/Welcome')),
)
const StartComponent = React.lazy(() => retry(() => import('../screens/Start')))
const AuthenticatedRoutes = React.lazy(() =>
  retry(() => import('./authRoutes')),
)

class Main extends Component {
  static contextType = Auth0Context

  state = {
    showRefresh: false,
  }

  refreshTokenSpa = async () => {
    const auth0Context = this.context

    try {
      const userInfo = await auth0Context?.getUser()
      let accessToken = await getToken(auth0Context)

      if (userInfo && accessToken) {
        await Auth.getInstance().setSessionSpa(
          accessToken,
          userInfo,
          this.props.dispatch,
        )
      }
    } catch (error) {
      log.error('---> Main --> refreshTokenSpa -> catch ERROR: ', error)
      Sentry.captureException(error)
      Auth.getInstance().logout(this.props.dispatch, auth0Context)
    }
  }

  checkVersion = () => {
    fetch(`${process.env.PUBLIC_URL}/gitInfo.txt`, {
      cache: 'no-cache',
    })
      .then(version => {
        return version.text().then(text => {
          if (text.trim() !== process.env.REACT_APP_GIT_COMMIT_SHA) {
            this.setState({ showRefresh: true })
          }
        })
      })
      .catch(err => {
        log.error(err)
      })
  }

  async componentDidMount() {
    if (window.location.hostname !== 'localhost') {
      this.checkVersionInterval = setInterval(this.checkVersion, 120000)
    }
    if (this.props.user) {
      Auth.userProfile = this.props.user
    }

    if (Capacitor.isNative) {
      await this.refreshTokenSpa()
    }
  }

  componentWillUnmount() {
    if (this.checkVersionInterval) {
      clearInterval(this.checkVersionInterval)
    }

    // unsubscribe alarms
    subscribeAlarmObject.unsubscribe()
  }

  render() {
    const { user } = this.props
    const { showRefresh } = this.state

    return (
      <Grommet full theme={noxtheme}>
        <Suspense
          fallback={
            <LoadingIndicator
              full
              callback
              message="Identiv Nox loading..."
              width="200"
            />
          }
        >
          <Router basename={CONSTANTS.baseorigin} history={history}>
            <Box basis="full" fill overflow="auto">
              {showRefresh && (
                <Notification
                  onClick={() => {
                    this.setState({ showRefresh: false })
                    window.location.reload(true)
                  }}
                />
              )}
              <Switch>
                <Route
                  exact
                  path="/welcome"
                  render={() => {
                    if (
                      user &&
                      Object.keys(user).length &&
                      !window.location.href.includes('?email=')
                    ) {
                      return <Redirect to={DEFAULT_HOME_PAGE} />
                    }
                    return <WelcomeComponent />
                  }}
                />
                <Route path="/login" component={LoginComponent} />
                <Route path="/callback" component={LoginCallBackComponent} />
                <Route exact path="/start" component={StartComponent} />
                <AuthenticatedRoutes />
                <Route
                  exact
                  path="/"
                  render={() => {
                    log('Should have redirect for default route')

                    let recentUrl =
                      localStorage.getItem(KEYS.recentUrlKey) ||
                      DEFAULT_HOME_PAGE

                    let isAuthenticated = Auth.getInstance().isAuthenticated()

                    if (Capacitor.isNative) {
                      const auth0Context = this.context
                      let auth0Client = auth0Context?.auth0Client
                      isAuthenticated = auth0Client && isAuthenticated
                    }

                    if (isAuthenticated) {
                      return <Redirect from="/" to={recentUrl} />
                    }
                    return <Redirect from="/" to="/login" />
                  }}
                />
              </Switch>
            </Box>
          </Router>
        </Suspense>
      </Grommet>
    )
  }
}

function Notification(props) {
  const { onClick } = props
  return (
    <Box
      pad="none"
      direction="row"
      background="status-warning"
      animation="fadeIn"
    >
      <Box flex color="light-1" size="small" pad="small">
        A newer version of Nox is available, dismiss this message to refresh.
      </Box>
      <Box width="50px">
        <Button
          icon={<Close size="medium" color="light-1" />}
          onClick={onClick}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({ user: state.user })
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(Main)
