const CONSTANTS = {
  basename: '',
  lengthBaseName: 0, // here is length of basename
  baseorigin: '',
}
export default CONSTANTS

const KEYS = {
  recentUrlKey: `recent_url`,
  expiredKey: `expired`,
  crispStatus: `crisp_status`,
  appType: 'noxapptype',
}

const ROUTES = {
  doors: '/config/doors',
  users: '/config/users',
  rules: '/config/rules',
  schedules: '/config/schedules',
}
export { KEYS, ROUTES }

const BORDER_RADIUS = 5
export { BORDER_RADIUS }

export const DEFAULT_HOME_PAGE = '/events/dashboard'
export const DEFAULT_DEALER_HOME_PAGE = '/tenants'
