import React, { Component, createContext, useContext } from 'react'

import { Capacitor } from '@capacitor/core'
import StaticConfig from '../StaticConfig'
import createAuth0Client from '@auth0/auth0-spa-js'
import { getRedirectUri } from './Auth'

// create the context
export const Auth0Context = createContext()
export const useAuth0 = () => useContext(Auth0Context)

// const CONFIG = StaticConfig(window.location.hostname)

// create a provider
export class Auth0Provider extends Component {
  state = {
    auth0Client: null,
    isLoading: true,
    isAuthenticated: false,
    user: null,
  }

  componentDidMount() {
    this.initializeAuth0()
  }

  // initialize the auth0 library
  initializeAuth0 = async () => {
    const CONFIG = StaticConfig(window.location.hostname)
    let config = {
      domain: CONFIG.auth0.domain,
      client_id: CONFIG.auth0.clientID,
      redirect_uri: getRedirectUri(),
      name: CONFIG.auth0.name, // database name
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
    }

    if (Capacitor.getPlatform() === 'ios') {
      config = { ...config, connection: CONFIG.auth0.name } // only show login type email/password}
    }

    const auth0Client = await createAuth0Client(config)
    this.setState({ auth0Client })
  }

  render() {
    const { auth0Client } = this.state
    const { children } = this.props

    if (auth0Client) {
      const configObject = {
        loginWithRedirect: (...p) => auth0Client?.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client?.getTokenSilently(...p),
        getIdTokenClaims: (...p) => auth0Client?.getIdTokenClaims(...p),
        handleRedirectCallback: (...p) =>
          auth0Client?.handleRedirectCallback(...p),
        getUser: (...p) => auth0Client?.getUser(...p),
        logout: (...p) => auth0Client?.logout(...p),
      }

      return (
        <Auth0Context.Provider value={configObject}>
          {children}
        </Auth0Context.Provider>
      )
    }

    return null
  }
}
