import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist5'

import localforage from 'localforage'
import rootReducer from './reducers/root'
import thunk from 'redux-thunk'

const persistConfig = {
  key: 'nox_root_db',
  storage: localforage,
  whitelist: ['user', 'alarm'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// export default compose(applyMiddleware(thunk))(createStore)(root)

export default () => {
  let store = createStore(persistedReducer, compose(applyMiddleware(thunk)))
  let persistor = persistStore(store)
  return { store, persistor }
}
