import { FlagContext } from '../App'
import PropTypes from 'prop-types'
import { useContext } from 'react'

export const KEYS_FLAG = {
  themeV1: 'themeV1',
  themeV2: 'themeV2',
  addDoorV1: 'addDoorV1',
  addDoorV2: 'addDoorV2',
  feature2: 'feature2',
  feature3: 'feature3',
}

Flag.propTypes = {
  authorizedFlags: PropTypes.arrayOf(PropTypes.string).isRequired,
}

// Same approach for defaultProps too
Flag.defaultProps = {
  authorizedFlags: [KEYS_FLAG.themeV1], // default load old theme
}

function Flag(props) {
  const [flagState] = useContext(FlagContext)

  let authorizedFlags = props.authorizedFlags || []

  let active = false
  authorizedFlags.forEach(element => {
    if (!active) {
      active = flagState[element] // check OR
    }
  })

  if (active) {
    return props.children
  }

  return null
}

export default Flag
