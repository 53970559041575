import Auth from '../../auth/Auth'
import { toast } from 'react-toastify'

toast.configure({
  position: 'bottom-left',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  style: { bottom: '2em', left: '0.5em' },
})

function Toast(props) {
  const { message, type, authError, ...rest } = props

  if (!authError && !Auth.getInstance().isAuthenticated()) return
  if (type) return toast[type](message, rest)
  return toast(message, rest)
}

export default Toast
