// (C) Copyright 2014-2015 Hewlett Packard Enterprise Development LP

import { NAV_ACTIVATE, NAV_ENABLE, NAV_RESPONSIVE, NAV_TYPE } from '../actions'

import { createReducer } from './utils'

const configs = [
  {
    title: 'Users',
    items: [
      { path: '/config/users', label: 'Users' },
      { path: '/config/roles', label: 'Roles' },
      { path: '/config/user_tags', label: 'Tags' },
    ],
  },
  {
    title: 'Doors',
    items: [
      { path: '/config/doors', label: 'Doors' },
      { path: '/config/groups', label: 'Groups' },
      { path: '/config/door_tags', label: 'Tags' },
    ],
  },
  {
    title: 'Access Rules',
    items: [
      { path: '/config/rules', label: 'Rules' },
      { path: '/config/schedules', label: 'Schedules' },
    ],
  },
]

const initialState = {
  active: true, // start with nav active
  enabled: true, // start with nav disabled
  responsive: 'multiple',
  mode: 'config',
  groups: configs,
}

const handlers = {
  [NAV_ACTIVATE]: (_, action) => {
    return { active: action.active, activateOnMultiple: undefined }
  },

  [NAV_ENABLE]: (_, action) => {
    return { enabled: action.enabled }
  },

  [NAV_RESPONSIVE]: (state, action) => {
    const result = { responsive: action.responsive }
    if (action.responsive === 'single' && state.active) {
      result.active = false
      result.activateOnMultiple = true
    } else if (action.responsive === 'multiple' && state.activateOnMultiple) {
      result.active = true
    }
    return result
  },

  [NAV_TYPE]: (state, action) => {
    if (action.mode === 'config') {
      return { mode: action.mode, groups: configs }
    } else if (action.mode === 'event') {
      return {
        mode: action.mode,
        groups: [
          {
            title: 'Events',
            items: [
              { path: '/events/view', label: 'Events' },
              { path: '/events/dashboard', label: 'Dashboard' },
            ],
          },
          {
            title: 'Integrations',
            items: [{ path: '/events/integrations', label: 'Integrations' }],
          },
        ],
      }
    }
    return {}
  },
}

export default createReducer(initialState, handlers)
