// See https://github.com/acdlite/flux-standard-action

// Session
export const SESSION_LOAD = 'SESSION_LOAD'
export const SESSION_LOGIN = 'SESSION_LOGIN'
export const SESSION_LOGOUT = 'SESSION_LOGOUT'

// Nav
export const NAV_ACTIVATE = 'NAV_ACTIVATE'
export const NAV_ENABLE = 'NAV_ENABLE'
export const NAV_RESPONSIVE = 'NAV_RESPONSIVE'
export const NAV_TYPE = 'NAV_TYPE'

export const FETCH_PROFILE = 'FETCH_PROFILE'

// setting
export const FETCH_SETTING = 'FETCH_SETTING'
export const UPDATE_SETTING = 'UPDATE_SETTING'

// alarm
// export const ACTIVE_ALARM = 'ACTIVE_ALARM'
// export const DISMISS_ALARM = 'DISMISS_ALARM'
export const CLOSED_NOTIFY_ALARM = 'CLOSED_ALARM'
export const INIT_ALARM_SUB = 'INIT_ALARM_SUB'
export const UPDATE_ALARM = 'UPDATE_ALARM'
export const UPDATE_DEALER_TO_TENANT = 'UPDATE_DEALER_TO_TENANT'
export const NEED_TO_SUBSCRIBE_ALARM = 'NEED_TO_SUBSCRIBE_ALARM'
export const NEED_TO_RELOAD = 'NEED_TO_RELOAD'

// global setting
export const UPDATE_GLOBAL_LOADING = 'UPDATE_GLOBAL_LOADING'

// theme
export const UPDATE_THEME = 'UPDATE_THEME'
