import { Box, CheckBox, Select, Text } from 'grommet'

import { Close } from 'grommet-icons'
import { IconButton } from 'grommet-controls'
import React from 'react'

class Option extends React.PureComponent {
  render() {
    const { option, selected } = this.props
    return (
      <Box
        direction="row-responsive"
        gap="small"
        align="center"
        pad="xsmall"
        margin="xsmall"
        width="350px"
      >
        <CheckBox checked={selected} />
        <Text>{option.label}</Text>
      </Box>
    )
  }
}

class CustomSelect extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedTags: [],
      filteredOpts: undefined,
      flagEnter: false,
      oldValue: { ...this.props.value },
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.enterFunction, true)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.enterFunction, true)
  }

  onSearch = value => {
    const val = value
    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      const newList = this.props.options.filter(item => {
        return item.label.toLowerCase().indexOf(val.toLowerCase()) > -1
      })
      return this.setState({ filteredOpts: newList })
    }
    return this.setState({ filteredOpts: undefined })
  }

  onOpen = () => {
    this.setState({ filteredOpts: undefined })
  }

  onChange = event => {
    if (this.state.flagEnter) {
      this.setState({ flagEnter: false })
    } else {
      if (this.props.onChange) {
        this.props.onChange(event)
      } else {
        this.props.handleSelectChange(event)
      }
    }
  }

  enterFunction = event => {
    if (
      !this.props.multiple &&
      event.type === 'keydown' &&
      event.code === 'Enter'
    ) {
      // event.preventDefault()

      if (this.state.filteredOpts && this.state.filteredOpts.length > 0) {
        this.setState({ flagEnter: true })
        const firstValue = this.state.filteredOpts[0]
        if (this.props.handleAutoSelectFirst) {
          this.props.handleAutoSelectFirst(firstValue) // auto select first item if filter has result
        }
      } else {
        this.setState({ flagEnter: true })
        const firstValue = this.props.value
        if (this.props.handleAutoSelectFirst) {
          this.props.handleAutoSelectFirst(firstValue) // set old value if filter has no result
          // this.setState({ filteredOpts: [...this.props.options] }) // trick to dropdown can display old value
          this.setState({ filteredOpts: undefined }) // trick to dropdown can display old value
        }
      }

      return false
    }
  }

  render() {
    const {
      value,
      options,
      multiple = false,
      isSearch = true,
      clearData, // undefined will not show button clear (x)
      canClear = true, // pass false will not show button clear (x)
    } = this.props
    const selectProps = {
      ...this.props,
      dropHeight: 'large',
      options: this.state.filteredOpts || options,
      onOpen: this.onOpen,
      messages: { multiple: 'Multiple items ...' },
      closeOnChange: !multiple,
      labelKey: 'label',
      valueKey: 'label',
    }
    if (isSearch) {
      selectProps.onSearch = this.onSearch
      selectProps.searchPlaceholder = 'Search ...'
    }
    if (!multiple) {
      if (value.value && value.value.idx) {
        selectProps.valueKey = v => v.value && v.value.idx
      }
      return (
        <Box direction="row" align="center">
          <Box flex width="100%">
            <Select {...selectProps} plain onChange={this.onChange}>
              {option => (
                <Box width="350px" margin="small">
                  <Text>{option.label}</Text>
                </Box>
              )}
            </Select>
          </Box>
          {value && canClear && clearData && (
            // show button clear (x) or not
            <Box
              width="0px"
              style={{
                position: 'relative',
                right: '68px',
              }}
            >
              <IconButton
                icon={<Close color="black" size="13px" />}
                onClick={() => {
                  if (clearData) {
                    clearData()
                  }
                }}
                style={{
                  backgroundColor: '#FFFFFF00',
                  boxShadow: 'none',
                }}
              />
            </Box>
          )}
        </Box>
      )
    }

    return (
      <Select {...selectProps} plain>
        {(option, index, options, state) => (
          <Option
            option={option}
            selected={
              multiple
                ? state.selected
                : value && value.label
                ? value.label === option.label
                : false
            }
          />
        )}
      </Select>
    )
  }
}

export default CustomSelect
