import {
  NEED_TO_RELOAD,
  NEED_TO_SUBSCRIBE_ALARM,
  UPDATE_DEALER_TO_TENANT,
  UPDATE_GLOBAL_LOADING,
} from '../actions'

const defaultFields = {
  add: null,
  helpTag: '',
  new: false,
  noToolbar: false,
  searchHeader: null,
  refresh: null,
  showDeletionsConfirmation: null,
  isShowLoading: false,
}
const defaultState = {
  size: '',
  title: '',
  tenantMode: false,
  needToSubscribeAlarm: false,
  needToReload: false,
  ...defaultFields,
}

export default function globalLayoutSetting(state = defaultState, action) {
  // with any action type
  if (action.type === 'addGlobalLayoutSetting') {
    return { ...state, ...defaultFields, ...action.pageSetting }
  } else if (action.type === UPDATE_DEALER_TO_TENANT) {
    let dealerToTenant = { tenantMode: false, dealerId: null, dealerName: null }
    if (action.tenantMode) {
      dealerToTenant = {
        tenantMode: action.tenantMode,
        dealerId: action.dealerId,
        dealerName: action.dealerName,
      }
    }

    const resp = {
      ...state,
      ...dealerToTenant,
    }
    return resp
  } else if (action.type === NEED_TO_SUBSCRIBE_ALARM) {
    const resp = {
      ...state,
      needToSubscribeAlarm: action.needToSubscribeAlarm,
    }
    return resp
  } else if (action.type === UPDATE_GLOBAL_LOADING) {
    return {
      ...state,
      isShowLoading: action.isShowLoading,
    }
  } else if (action.type === NEED_TO_RELOAD) {
    const resp = {
      ...state,
      needToReload: action.needToReload,
    }
    return resp
  }

  return state
}
