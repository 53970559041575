import CONSTANTS, { KEYS } from '../appsettings/constants'

import { createBrowserHistory } from 'history'

const history = createBrowserHistory({ basename: CONSTANTS.basename })
// OR export default createBrowserHistory({ basename: process.env.PUBLIC_URL })

history.listen((location, _action) => {
  if (
    !['/login', '/logout', '/welcome', '/callback'].includes(location.pathname)
  )
    localStorage.setItem(KEYS.recentUrlKey, location.pathname)
})

export default history
