import { UPDATE_THEME } from '../actions'

const initTheme = {
  color: {
    color1: '#1F4690',
    color2: '#FFA500',
    color3: '#FFA500',
    color4: '#FFE5B4',
    color5: '#FFFFFF',
  },
  logoUrl: `${process.env.PUBLIC_URL}/img/CircleNox.svg`,
}

// const initTheme = {
//   color: {
//     color1: '#293462',
//     color2: '#F24C4C',
//     color3: '#F24C4C',
//     color4: '#F7D716',
//     color5: '#FFFFFF',
//   },
//   logoUrl: `${process.env.PUBLIC_URL}/img/CircleNox.svg`,
// }

// const initTheme = {
//   color: {
//     color1: '#112B3C',
//     color2: '#F66B0E',
//     color3: '#F66B0E',
//     color4: '#EFEFEF',
//     color5: '#FFFFFF',
//   },
//   logoUrl: `${process.env.PUBLIC_URL}/img/CircleNox.svg`,
// }

// const initTheme = {
//   color: {
//     color1: '#781C68',
//     color2: '#9A0680',
//     color3: '#FFD39A',
//     color4: '#FFF5E1',
//     color5: '#FFFFFF',
//   },
//   logoUrl: `${process.env.PUBLIC_URL}/img/CircleNox.svg`,
// }

// const initTheme = {
//   color: {
//     color1: '#1B2F46', // side menu background
//     color2: '#E21D42', // floating button
//     color3: '#AB001C', // alarm bar, focused side menu item
//     color4: '#FFFFFFB3', // side menu item
//     color5: '#FFFFFF', // side menu active item
//   },
//   logoUrl: `${process.env.PUBLIC_URL}/img/CircleNox.svg`, // side menu logo
// }

export default function theme(state = initTheme, action) {
  switch (action.type) {
    case UPDATE_THEME: {
      return {
        color: action.theme?.color ?? initTheme.color,
        logoUrl: action.theme?.logoUrl ?? initTheme.logoUrl,
      }
    }
    default:
      return state
  }
}
