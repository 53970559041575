import { CLOSED_NOTIFY_ALARM, INIT_ALARM_SUB, UPDATE_ALARM } from '../actions'

const initAlarms = { tenantHost: false, isShow: false, countActiveAlarms: 0 }

export default function alarm(state = initAlarms, action) {
  switch (action.type) {
    case INIT_ALARM_SUB:
    case UPDATE_ALARM: {
      return {
        isShow: true,
        tenantHost: action.tenantHost,
        countActiveAlarms: action.countActiveAlarms,
      }
    }
    case CLOSED_NOTIFY_ALARM:
      return { ...state, countActiveAlarms: 0, isShow: false }
    default:
      return state
  }
}
