import CONSTANTS, {
  DEFAULT_DEALER_HOME_PAGE,
  DEFAULT_HOME_PAGE,
  KEYS,
} from '../appsettings/constants'
import {
  CognitoIdentity,
  CognitoIdentityCredentials,
  Credentials,
  config,
} from 'aws-sdk'
import {
  DEALER_URLS,
  UserAvatarCacheKey,
  buildUserLoginInfo,
  cacheImages,
  proccessUserLoginError,
  saveErrorToDebug,
} from '../service/ServiceUtils'
import {
  FETCH_PROFILE,
  INIT_ALARM_SUB,
  SESSION_LOGOUT,
  UPDATE_DEALER_TO_TENANT,
  UPDATE_THEME,
} from '../actions'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { Mutation, Query } from 'nox-api'

import { Capacitor } from '@capacitor/core'
import PushNotificationService from '../service/PushNotificationService'
import StaticConfig from '../StaticConfig'
import anylogger from 'anylogger'
import auth0 from 'auth0-js'
import history from '../components/history'
import introspectionQueryResultData from '../fragmentTypes'
import localforage from 'localforage'
import moment from 'moment'
import { toast } from 'react-toastify'

const log = anylogger('Auth')

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

// https://www.apollographql.com/docs/react/advanced/fragments.html#fragment-matcher
const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: object => object.idx || object.uuid || null,
})

const ROLE_ERROR = 'No right to access.'
const CONFIG = StaticConfig(window.location.hostname)
export const AUTH0_PARAMS = {
  scope: 'openid profile email read:current_user',
}
const pushService = new PushNotificationService()

export const APP_PACKAGE_NAME = 'com.identiv.nox'

export function getRedirectUri() {
  if (Capacitor.isNative) {
    if (Capacitor.getPlatform() === 'ios') {
      // iOS use universal link
      return `${
        CONFIG.auth0.universalLink
      }/${Capacitor.getPlatform()}/${APP_PACKAGE_NAME}/callback`
    }

    // android use deep link
    return `${APP_PACKAGE_NAME}://${
      CONFIG.auth0.domain
    }/${Capacitor.getPlatform()}/${APP_PACKAGE_NAME}/callback`
  }

  // web
  return `${window.location.origin}${CONSTANTS.basename}/callback`
}

/***
 * Use only for mobile device
 * getMobileLogoutUri
 */
export function getMobileLogoutUri() {
  if (Capacitor.isNative) {
    // iOS use universal link
    if (Capacitor.getPlatform() === 'ios') {
      return `${
        CONFIG.auth0.universalLink
      }/${Capacitor.getPlatform()}/${APP_PACKAGE_NAME}/logout`
    }

    // android use deep link
    return `${APP_PACKAGE_NAME}://localhost/logout`
  }
  return ''
}

/***
 * Use only for mobile device
 * getLoginHint when login dealer as tenant
 */
export function getLoginHint() {
  let dealerToTenantLocalData = JSON.parse(
    localStorage.getItem(UPDATE_DEALER_TO_TENANT),
  )

  if (dealerToTenantLocalData) {
    let tenantID = dealerToTenantLocalData.dealerId
    let loginHint = JSON.stringify({ tenantID })

    return { login_hint: loginHint }
  }

  return null
}

/***
 * Use only for mobile device
 * auth0-spa getTokenSilently
 */
export async function getToken(
  auth0Context,
  needToCheckAuthenticated = true,
  forceNewToken = false,
) {
  if (!auth0Context) {
    return null
  }

  let _loginHint = getLoginHint()

  let _accessToken = null
  let _options = null
  if (forceNewToken) {
    _options = { ignoreCache: true }
  }
  if (_loginHint) {
    _options = { ..._loginHint, ignoreCache: true }
  }

  log('---> getTokenSilently --> _options: ', JSON.stringify(_options))

  const _isAuthenticated = !!Auth.userProfile && !!Auth.userProfile.accessToken
  if (_isAuthenticated || !needToCheckAuthenticated) {
    if (_options) {
      _accessToken = await auth0Context?.getTokenSilently(_options)
    } else {
      _accessToken = await auth0Context?.getTokenSilently()
    }
  }

  return _accessToken
}

function isCredentialExpired(timeExpire) {
  if (!timeExpire) {
    return true
  }

  const expireTime = moment(timeExpire).subtract(2, 'minute')
  if (moment().isAfter(expireTime)) {
    return true
  }

  return false
}

export function getCredetial(cb) {
  if (
    !config.credentials ||
    isCredentialExpired(config.credentials.Expiration)
  ) {
    config.region = CONFIG.cognito.region
    config.credentials = new CognitoIdentityCredentials({
      IdentityPoolId: CONFIG.cognito.identityPoolId,
    })
    const getIdInput = {
      IdentityPoolId: CONFIG.cognito.identityPoolId,
      AccountId: CONFIG.cognito.accountId,
      Logins: {
        [CONFIG.auth0.domain]: Auth.userProfile.accessToken,
      },
    }
    const cognitoidentity = new CognitoIdentity()
    cognitoidentity.getId(getIdInput, (err, resp) => {
      if (err) return cb(err)
      cognitoidentity.getCredentialsForIdentity(
        {
          IdentityId: resp.IdentityId,
          Logins: {
            [CONFIG.auth0.domain]: Auth.userProfile.accessToken,
          },
        },
        (err, data) => {
          if (err) return cb(err)
          config.credentials = data.Credentials
          return cb(null, data.Credentials)
        },
      )
    })
  } else {
    return cb(null, config.credentials)
  }
}

export default class Auth {
  static myInstance

  static auth0 = new auth0.WebAuth({
    domain: CONFIG.auth0.domain,
    clientID: CONFIG.auth0.clientID,
    redirectUri: getRedirectUri(),
    responseType: 'token id_token',
    ...AUTH0_PARAMS,
  })

  static userProfile = {}
  static initializedCustomerSupporting = false

  static getInstance() {
    if (!Auth.myInstance) {
      Auth.myInstance = new Auth()
    }
    return this.myInstance
  }

  googleLogin() {
    localStorage.setItem('loginType', 'google')
    Auth.auth0.authorize({
      connection: 'google-oauth2',
      prompt: 'select_account',
    })
  }

  getS3Client(cb) {
    getCredetial((err, credential) => {
      if (err) return cb(err)
      const config = {
        params: { Bucket: CONFIG.s3.bucket },
        region: CONFIG.s3.region,
        credentials: new Credentials({
          accessKeyId: credential.AccessKeyId,
          sessionToken: credential.SessionToken,
          secretAccessKey: credential.SecretKey,
        }),
      }
      import('../service/S3').then(({ default: S3 }) => {
        return cb(null, new S3(config))
      })
    })
  }

  passwordLogin(email, password, cb) {
    // const url = `https://${CONFIG.auth0.domain}/oauth/token`
    // return fetch(url, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     client_id: CONFIG.auth0.clientID,
    //     scope: AUTH0_PARAMS.scope,
    //     grant_type: 'password',
    //     username: email,
    //     password: password,
    //     apptype: 'web',
    //     // tenantID: "testing custom token with this tenantID"
    //   }),
    // })
    localStorage.setItem('loginType', 'password')
    Auth.getAuth0().login(
      {
        email,
        password,
        domain: CONFIG.auth0.domain,
        clientID: CONFIG.auth0.clientID,
        scope: AUTH0_PARAMS.scope,
        redirectUri: getRedirectUri(),
        responseType: 'token id_token',
      },
      cb,
    )
  }

  static clear() {
    localStorage.removeItem('auth0_expires_at')
    localStorage.removeItem('features')
    localStorage.removeItem('history') // control back button
    localStorage.removeItem('didLoadTheme')
    localStorage.removeItem(UPDATE_DEALER_TO_TENANT)
    // remove cache avatar
    localStorage.removeItem(UserAvatarCacheKey)

    Auth.userProfile = {}
    Auth.initializedCustomerSupporting = false
  }

  logout = async (dispatch, auth0Context) => {
    if (this.isAuthenticated()) {
      await pushService.unregisterPushNotification()
    }
    // clear toast
    toast.clearWaitingQueue()
    toast.dismiss()
    Auth.clear()
    // clear indexdb
    localforage.clear(err => {
      log.error('---> logout --> clear indexdb with error: ', err)
    })

    if (dispatch) {
      dispatch({ type: SESSION_LOGOUT })
      dispatch({
        type: UPDATE_DEALER_TO_TENANT,
        tenantMode: false,
      })
    }
    log(moment().toString(), '---> logout --> forced logout')

    if (Capacitor.isNative && auth0Context) {
      log('---> logout --> isNative -> auth0Context: ', auth0Context)

      let logoutUri = getMobileLogoutUri()
      log('---> logout --> isNative -> logoutUri: ', logoutUri)
      auth0Context.logout({
        returnTo: logoutUri,
      }) // auth0-spa logout
    } else {
      // logout & navigate to the home route
      let returnTo = encodeURI(
        `${window.location.origin}${CONSTANTS.basename}/login`,
      )
      log('---> logout --> returnTo: ', returnTo)

      /*
      if (Capacitor.isNative) {
        //refer https://auth0.com/blog/adding-auth0-to-an-ionic-angular-app-using-auth-connect/
        returnTo = `${APP_PACKAGE_NAME}://localhost/login`
      }
      */

      // auth logout
      // Auth.auth0.logout({ clientID: CONFIG.auth0.clientID })
      history.push(
        `/logout?returnTo=${returnTo}&client_id=${CONFIG.auth0.clientID}`,
      )
    }
  }

  handleAuthentication(dispatch) {
    Auth.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.handleSuccess(authResult, dispatch)
      } else if (err) {
        saveErrorToDebug(err)
        this.handleError(err, dispatch, true)
      }
    })
  }

  handleSuccess(authResult, dispatch) {
    log('---> handleSuccess --> authResult success')
    this.setSession(authResult, dispatch)
  }

  handleError(err, dispatch, forceLogout) {
    log.warn('Authentication error: ', err)
    if (err.errorDescription) {
      localStorage.setItem('login_error', err.errorDescription)
      localStorage.setItem('error', JSON.stringify(err))
      if (err.errorDescription === ROLE_ERROR) {
        history.push('/welcome')
      } else if (forceLogout) {
        this.logout(dispatch)
      }
    }
  }

  handleMobileError(errorDescription, dispatch, forceLogout) {
    log.warn('Authentication errorDescription: ', errorDescription)
    if (errorDescription) {
      localStorage.setItem('login_error', errorDescription)
      if (errorDescription === ROLE_ERROR) {
        history.push('/welcome')
      } else if (forceLogout) {
        this.logout(dispatch)
      }
    }
  }

  auth0CheckSession(tenantID, cb) {
    const opt = { ...AUTH0_PARAMS }
    if (tenantID) {
      opt.login_hint = JSON.stringify({ tenantID })
    }

    Auth.getAuth0().checkSession(opt, cb)
  }

  loadTheme(theme, dispatch) {
    if (theme) {
      let themeData = JSON.parse(theme)

      let logoImages = themeData.logo

      cacheImages(logoImages, null)
        .then(resp => {
          if (resp) {
            themeData.logoUrl = resp.url
            dispatch({ type: UPDATE_THEME, theme: themeData })
          }
        })
        .catch(e => {
          log.error(e)
        })
    }
  }

  /**
   * Use only for web
   * On mobile device use auth0-spa, not use auth0-checkSession
   */
  async refreshAuth0Session(dispatch, tenantID) {
    return new Promise((resolve, reject) => {
      this.auth0CheckSession(tenantID, async (err, authResult) => {
        if (err) {
          log.error('---> refreshAuth0Session --> error: ', err)
          saveErrorToDebug(err)

          this.logout(dispatch)

          return reject(err)
        }

        Auth.userProfile.idToken = authResult.idToken
        Auth.userProfile.accessToken = authResult.accessToken

        await Auth.getInstance().cacheAuth0(authResult, false)

        // save theme
        if (Auth.userProfile.theme) {
          this.loadTheme(Auth.userProfile.theme, dispatch)
        }

        log('---> refreshAuth0Session --> success')
        return resolve()
      })
    })
  }

  async getUserLoginInfos() {
    const user = (
      await (await this.getApolloClient()).query({
        query: Query.getUserLoginInfos,
        fetchPolicy: 'network-only',
      })
    ).data.getUserLoginInfos

    log('---> getUserLoginInfos --> UserLoginInfos', user)

    // if (user.tenantHost) {
    //   throw new Error('Your account is a dealer user')
    // }

    Auth.userProfile.tenantName = user.tenantName
    Auth.userProfile.tenantId = user.tenantId
    Auth.userProfile.id = user.userId
    Auth.userProfile.name = user.userName
    Auth.userProfile.preferences = user.preferences
    Auth.userProfile.tenantHost = user.tenantHost
    Auth.userProfile.countActiveAlarms = user.countActiveAlarms
    Auth.userProfile.dealerId = user.dealerId
    Auth.userProfile.theme = user.theme

    localStorage.setItem('features', user.features)

    if (Auth.userProfile.theme && window.nox.store.dispatch) {
      this.loadTheme(Auth.userProfile.theme, window.nox.store.dispatch)
    }

    return user
  }

  async cacheAuth0(authResult, needNewApolloClient = true) {
    // Set the time that the Access Token will expire at
    const now = new Date().getTime()
    localStorage.setItem('auth0_expires_at', authResult.expiresIn * 1000 + now)

    if (needNewApolloClient) {
      await this.getApolloClient(true)
    }
  }

  async cacheAuth0Spa() {
    await this.getApolloClient()
  }

  setSession(authResult, dispatch) {
    Auth.userProfile = Object.assign({}, Auth.userProfile, {
      accessToken: authResult.accessToken,
      idToken: authResult.idToken,
      auth0Id: authResult.idTokenPayload.sub,
      email: authResult.idTokenPayload.email,
      picture: authResult.idTokenPayload.picture,
      nickname: authResult.idTokenPayload.nickname,
      signedUpTime:
        authResult.idTokenPayload['https://identivnox.com/claims/created_at'],
    })

    this.cacheAuth0(authResult).then(() => {
      this.getUserLoginInfos()
        .then(user => {
          //call api to nox login event

          if (
            !user.features ||
            !user.features.includes('ignore_synthetics') ||
            user.userName !== 'Yo'
          ) {
            this.updateNoxLoginEvent()
          }
          /*
          window.CRISP_READY_TRIGGER = function() {
            window.$crisp.push([
              'set',
              'session:event',
              [
                [
                  [
                    'login',
                    {
                      time: moment().format('dddd, MMMM Do YYYY, h:mm:ss a Z'),
                    },
                    'green',
                  ],
                ],
              ],
            ])
          }
          */
          Auth.initializedCustomerSupporting = true
          // init credential for s3
          getCredetial(() => {})

          let recentUrl =
            localStorage.getItem(KEYS.recentUrlKey) || DEFAULT_HOME_PAGE
          log('---> setSession --> recentUrl: ', recentUrl)
          pushService.initPushNotification(true)

          // fix recentUrl of a dealer
          if (DEALER_URLS.includes(recentUrl) && !Auth.userProfile.tenantHost) {
            recentUrl = DEFAULT_HOME_PAGE
          } else if (
            !DEALER_URLS.includes(recentUrl) &&
            Auth.userProfile.tenantHost
          ) {
            recentUrl = DEFAULT_DEALER_HOME_PAGE
          }
          // case for access any a detail url and press back button (response home page)
          history.push(recentUrl)

          // save history location for control back button
          // see buildActionForBackButton()  in ServiceUtils.js
          localStorage.setItem('history', JSON.stringify(history.location))
          dispatch({ type: FETCH_PROFILE, user: Auth.userProfile })

          // save theme
          if (user.theme) {
            this.loadTheme(user.theme, dispatch)
          }

          // show active alarm
          dispatch({
            type: INIT_ALARM_SUB,
            countActiveAlarms: Auth.userProfile.countActiveAlarms || 0,
            tenantHost: Auth.userProfile.tenantHost,
          })
        })
        .catch(e => {
          log.error('---> setSession --> getUserLoginInfos -> catch ERROR: ', e)
          proccessUserLoginError(e, dispatch, this.logout)
        })
    })
  }

  setSessionSpa(accessToken, userInfo, dispatch) {
    Auth.userProfile = Object.assign({}, Auth.userProfile, {
      accessToken: accessToken,
      // idToken: userInfo.idToken,
      // auth0Id: userInfo.idTokenPayload.sub,
      email: userInfo.email,
      picture: userInfo.picture,
      nickname: userInfo.nickname,
      // signedUpTime: userInfo.idTokenPayload['https://identivnox.com/claims/created_at'],
    })

    this.cacheAuth0Spa().then(() => {
      this.getUserLoginInfos()
        .then(user => {
          //call api to nox login event
          if (
            !user.features ||
            !user.features.includes('ignore_synthetics') ||
            user.userName !== 'Yo'
          ) {
            this.updateNoxLoginEvent()
          }
          /*
          window.CRISP_READY_TRIGGER = function() {
            window.$crisp.push([
              'set',
              'session:event',
              [
                [
                  [
                    'login',
                    {
                      time: moment().format('dddd, MMMM Do YYYY, h:mm:ss a Z'),
                    },
                    'green',
                  ],
                ],
              ],
            ])
          }
          */

          Auth.initializedCustomerSupporting = true

          // init credential for s3
          getCredetial(() => {})

          let recentUrl =
            localStorage.getItem(KEYS.recentUrlKey) || DEFAULT_HOME_PAGE

          pushService.initPushNotification(true)
          // case for access any a detail url and press back button (response home page)

          history.push(recentUrl)
          // save history location for control back button
          // see buildActionForBackButton()  in ServiceUtils.js
          localStorage.setItem('history', JSON.stringify(history.location))
          dispatch({ type: FETCH_PROFILE, user: Auth.userProfile })

          // save theme
          if (user.theme) {
            this.loadTheme(user.theme, dispatch)
          }

          // show active alarm
          dispatch({
            type: INIT_ALARM_SUB,
            countActiveAlarms: Auth.userProfile.countActiveAlarms || 0,
            tenantHost: Auth.userProfile.tenantHost,
          })
        })
        .catch(e => {
          log.error(
            '---> setSessionSpa --> getUserLoginInfos -> catch ERROR: ',
            e,
          )
          proccessUserLoginError(e, dispatch, this.logout)
        })
    })
  }

  updateSessionSpa(accessToken) {
    Auth.userProfile.accessToken = accessToken
  }

  async getApolloClient(refresh = false) {
    log('---> getApolloClient --> BEGIN')

    /**
     * Only refresh token on web when use auth0
     */
    // BEGIN: refresh token for web
    if (Auth.userProfile.accessToken && !Capacitor.isNative) {
      const expiredTime = localStorage.getItem('auth0_expires_at')

      const now = moment()

      let isAuth0Expired = false
      if (expiredTime) {
        let expiredDate = moment(parseInt(expiredTime))
        isAuth0Expired = expiredDate.subtract(4, 'minute').isBefore(now)

        log(
          `---> getApolloClient --> auth0ExpiresAt: ${expiredDate}, isAuth0Expired: ${isAuth0Expired}, refresh: ${refresh}`,
        )
      }

      if (isAuth0Expired || refresh) {
        try {
          log('---> getApolloClient --> BEGIN refreshAuth0Session')
          let dealerToTenantLocalData = JSON.parse(
            localStorage.getItem(UPDATE_DEALER_TO_TENANT),
          )
          await Auth.getInstance().refreshAuth0Session(
            window.nox.store.dispatch,
            dealerToTenantLocalData ? dealerToTenantLocalData.dealerId : '',
          )
          log('---> getApolloClient --> END refreshAuth0Session')
        } catch (error) {
          log.error(
            now.toString(),
            '---> getApolloClient --> refreshAuth0Session -> catch ERROR: ',
            error,
          )
        }
      }
    }
    // END: refresh token for web

    const { default: AWSAppSyncClient } = await import('aws-appsync')

    try {
      this.client = new AWSAppSyncClient(
        {
          url: CONFIG.appsync.url,
          region: CONFIG.appsync.region,
          auth: {
            type: 'OPENID_CONNECT',
            jwtToken: () => Auth.userProfile.accessToken,
          },
          disableOffline: true,
          onError: ({ networkError, graphQLErrors }) => {
            log.error(
              '---> getApolloClient --> AWSAppSyncClient -> graphQLErrors',
              graphQLErrors,
            )
            log.error(
              '---> getApolloClient --> AWSAppSyncClient -> networkError',
              networkError,
            )
          },
        },
        {
          cache,
        },
      )
    } catch (e) {
      log.error('---> getApolloClient --> AWSAppSyncClient -> catch ERROR: ', e)
    }

    log('---> getApolloClient --> END')

    return this.client
  }

  isAuthenticated() {
    return !!Auth.userProfile && !!Auth.userProfile.accessToken
  }

  checkExpiredAuthentication() {
    const expiredTime = localStorage.getItem('auth0_expires_at')
    if (!!expiredTime && Auth.userProfile.accessToken) {
      const now = moment()
      const isAuth0Expired = moment(parseInt(expiredTime))
        .subtract(4, 'minute')
        .isBefore(now)
      return isAuth0Expired
    }
    return false // on mobile device use auth0-spa, always not expired
  }

  static getAuth0() {
    return Auth.auth0
  }

  async updatePreferences(notifications) {
    let toUpdateNotif = []
    if (notifications.cbControllerOff) toUpdateNotif.push('CONTROLLER_OFFLINE')
    if (notifications.cbControllerOn) toUpdateNotif.push('CONTROLLER_ONLINE')
    if (notifications.cbAccessDenied) toUpdateNotif.push('ACCESS_DENIED')
    if (notifications.cbAccessGranted) toUpdateNotif.push('ACCESS_GRANT')
    if (notifications.cbReaderTemper) toUpdateNotif.push('READER_TAMPER')
    if (notifications.cbRequestToExit) toUpdateNotif.push('REQUEST_TO_EXIT')
    return await (await this.getApolloClient()).mutate({
      mutation: Mutation.updatePreferences,
      variables: {
        input: {
          preferences: toUpdateNotif, //["CONTROLLER_ONLINE", "CONTROLLER_ONLINE", "ACCESS_DENIED", "ACCESS_GRANT"]
        },
      },
    })
  }

  async updateNoxLoginEvent() {
    let loginInfo = await buildUserLoginInfo()
    let appVersion = ''
    await fetch(`${process.env.PUBLIC_URL}/gitInfo.txt`, {
      cache: 'no-cache',
    }).then(version => {
      version.text().then(text => {
        appVersion = text.trim()
      })
    })
    let inputData = {
      time: new Date(),
      loginInfo: JSON.stringify({
        ...loginInfo,
        appVersion: process.env.REACT_APP_GIT_COMMIT_SHA || appVersion,
        loginType: localStorage.getItem('loginType'),
      }),
    }
    log('---> updateNoxLoginEvent --> inputData: ', inputData)
    this.client.mutate({
      mutation: Mutation.createLoginEvent,
      variables: {
        input: inputData,
      },
    })
  }

  async registerWithSNS(deviceToken) {
    return (await this.getApolloClient())
      .mutate({
        mutation: Mutation.createPushNotificationEndpoint,
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            token: deviceToken,
            userId: Auth.userProfile.id,
            platform:
              Capacitor.getPlatform() === 'ios' ? 'IOS_ADMIN' : 'ANDROID_ADMIN',
          },
        },
      })
      .then(data => {
        if (data && Object.prototype.hasOwnProperty.call(data, 'data')) {
          const endpointArn = data.data.createPushNotificationEndpoint
          log('---> registerWithSNS --> endpointArn :', endpointArn)
          if (endpointArn) this._storeEndpointArn(endpointArn)
        }
      })
      .catch(err => {
        log.error('---> registerWithSNS --> catch ERROR :' + err)
      })
  }

  async unregisterWithSNS() {
    const endpointArn = this._retrieveEndpointArn()
    if (!endpointArn) {
      return
    }
    return await (await this.getApolloClient())
      .mutate({
        mutation: Mutation.deletePushNotificationEndpoint,
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            userId: Auth.userProfile.id,
            endpoint: endpointArn,
          },
        },
      })
      .then(result => {
        log('---> unregisterWithSNS --> result :', result)
      })
      .catch(err => {
        log('---> unregisterWithSNS --> catch ERROR :', err)
      })
  }

  _retrieveEndpointArn() {
    return localStorage.getItem('endpoint_arn')
  }

  _storeEndpointArn(endpointArn) {
    // Write the platform endpoint ARN to permanent storage.
    localStorage.setItem('endpoint_arn', endpointArn)
  }
}
