import { Box, FormField, RadioButton, Text, TextArea, TextInput } from 'grommet'
import {
  DOCS_LINK,
  buildCreateEventSubscriptionResult,
  buildSubscription,
} from '../../service/ServiceUtils'
import { IconButton, NumberInput } from 'grommet-controls'
import { Query, Subscription } from 'nox-api'

import { APP_PACKAGE_NAME } from '../../auth/Auth'
import Auth from '../../auth/Auth'
import ControlButton from '../elements/ControlButton'
import { Copy } from 'grommet-icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import CustomSelect from '../elements/CustomSelect'
import React from 'react'
import anylogger from 'anylogger'
import compose from 'lodash.flowright'
import { graphql } from 'react-apollo'
import moment from 'moment-timezone'
import { orderBy } from 'lodash'
import showToast from '../elements/Toast'

const log = anylogger('Util')

const EMPTY_OPTION = { value: undefined, label: 'None' }
export const DEFAULT_TIMEZONE = 'US/Pacific'
export const DEFAULT_RELAY_TIME = '6'

export const TIMEZONES = moment.tz.names().map(name => {
  return { label: name, value: name }
})

function makeLabel(title) {
  return (
    <Text key={'label_' + title} style={{ marginBottom: '0' }}>
      {title}
    </Text>
  )
}

export function ShowTextWithCopy({ text, onCopy }) {
  return (
    <Box direction="row-responsive" align="center">
      <TextInput plain disabled value={text} />
      <CopyToClipboard text={text} onCopy={onCopy}>
        <IconButton icon={<Copy />} />
      </CopyToClipboard>
    </Box>
  )
}

function makeTextInputField(
  setState,
  title,
  value,
  error,
  bindName,
  placeHolder,
  inputProps = {},
) {
  const fields = [makeLabel(title)]

  const { contentProps = {} } = inputProps
  let type = Object.prototype.hasOwnProperty.call(inputProps, 'inputType')
    ? inputProps.inputType
    : 'text'
  fields.push(
    <FormField
      key={'formfield_' + title}
      error={error}
      contentProps={contentProps}
    >
      {type === 'number' && (
        <NumberInput
          placeholder={placeHolder}
          value={value}
          onChange={event => handleChange(setState, bindName, event)}
          {...inputProps}
          onKeyDown={e => {
            if (type === 'number') {
              preventCharacterKey(e, value)
            }
          }}
          style={{ border: 'none' }}
        />
      )}
      {type !== 'number' && (
        <TextInput
          placeholder={placeHolder}
          value={value}
          onChange={event => handleChange(setState, bindName, event)}
          {...inputProps}
          onKeyDown={e => {
            if (type === 'number') {
              preventCharacterKey(e, value)
            }
          }}
        />
      )}
    </FormField>,
  )
  return fields
}

function makeTextAreaField(
  setState,
  title,
  value,
  error,
  bindName,
  inputProps = {},
) {
  const fields = [makeLabel(title)]
  fields.push(
    <FormField key={'formfield_' + title} error={error}>
      <TextArea
        rows="4"
        wrap="off"
        value={value}
        onChange={event => handleChange(setState, bindName, event)}
        {...inputProps}
      />
    </FormField>,
  )
  return fields
}

function makeTextInputFieldDisabled(title, fieldName, children) {
  let fields = [makeLabel(title)]
  if (children) {
    fields = [
      <Box direction="row">
        {makeLabel(title)}
        {children}
      </Box>,
    ]
  }
  fields.push(
    <FormField key={'formfield_' + title}>
      <TextInput value={fieldName} disabled />
    </FormField>,
  )
  return fields
}

function makeSelectField(
  setState,
  title,
  value = {},
  options,
  bindName,
  inputProps = {},
) {
  const fields = [makeLabel(title)]

  const props = {
    options,
    value,
    multiple: false,
    labelKey: 'label',
    // onChange: event => handleSelectChange(setState, bindName, event),
    handleSelectChange: event => {
      handleSelectChange(setState, bindName, event)
    },
    handleAutoSelectFirst: value => {
      handleAutoSelectFirst(setState, bindName, value)
    },
    clearData: () => {
      handleClear(setState, bindName)
    },
    placeholder: 'None',
  }
  fields.push(
    <FormField key={'formfield_' + title}>
      <CustomSelect {...props} {...inputProps} />
    </FormField>,
  )
  return fields
}

function makeCustomSelectField(
  title,
  value = [],
  options,
  placeholder,
  onChange,
) {
  const fields = [makeLabel(title)]
  const props = {
    placeholder,
    options,
    value,
    onChange,
    labelKey: 'label',
  }
  fields.push(
    <FormField key={'formfield_' + title}>
      <CustomSelect {...props} />
    </FormField>,
  )
  return fields
}

function makeSelectMultipleField(
  setState,
  title,
  value = [],
  options,
  bindName,
  inputProps = {},
) {
  const fields = [makeLabel(title)]
  fields.push(
    <FormField key={'formfield_' + title}>
      <CustomSelect
        placeholder="None"
        options={options}
        multiple
        value={value}
        onChange={event =>
          handleSelectChange(setState, bindName, { ...event, value })
        }
        {...inputProps}
      />
    </FormField>,
  )
  return fields
}

function handleChange(setState, field, event) {
  const {
    target: { value },
  } = event

  const fields = ['name', 'email', 'url', 'relayTime']
  if (fields.includes(field)) {
    setState({ [field + 'Error']: null })
  }
  if (!field) {
    setState(value)
  } else {
    setState({
      [field]: value,
    })
  }
}

function handleSelectChange(setState, field, event) {
  const { option, value } = event
  let newValue = value
  if (option && Array.isArray(newValue)) {
    // 'newvalue is array is array' ? 'multiple': 'single'
    let filteredList = value.filter(item => item.label === option.label)
    if (filteredList.length > 0) {
      newValue = value.filter(item => item.label !== option.label)
    } else {
      newValue = [...newValue, option]
    }
  }
  setState({
    [field]: newValue,
  })
}

function handleAutoSelectFirst(setState, field, value) {
  setState({
    [field]: value,
  })
}

function handleClear(setState, field) {
  setState({
    [field]: '',
  })
}

const TYPES = [
  'door',
  'door_group',
  'door_tag',
  'user',
  'user_role',
  'user_tag',
  'controller',
  'schedule',
  'nfc_tag',
]
function filterConfigs(configs = [], allConfigs = []) {
  const fields = {}
  TYPES.forEach(type => (fields[type] = []))
  configs.forEach(config => {
    const type = TYPES.find(type => config.link.startsWith(`${type}:`))
    if (type) {
      const item = allConfigs.find(cfg => config.link === cfg.tid)
      if (item) {
        return fields[type].push({ value: item, label: item.name })
      }
    }
  })
  return fields
}

export function getControllerFromConfig(configs = [], allConfigs = []) {
  if (!configs.length || !allConfigs.length) return null
  const config = configs.find(v => v.link.startsWith('controller:'))
  if (!config) return null
  let controller = null
  for (const cfg of allConfigs) {
    if (config.link === cfg.tid) {
      controller = cfg
      break
    }
  }
  return controller
}

function filterConfigOptions(allConfigs) {
  const options = {}
  TYPES.forEach(type => (options[type] = []))
  allConfigs.forEach(config => {
    const type = TYPES.find(type => config.tid.startsWith(`${type}:`))
    if (type) {
      options[type].push({ value: config, label: config.name })
    }
  })
  TYPES.forEach(
    type =>
      (options[type] = orderBy(
        options[type],
        [option => option.label.toLowerCase()],
        ['label'],
      )),
  )
  return options
}

function makeRadioField(id, label, checked, onChange, disabled = false) {
  return (
    <RadioButton
      key={id}
      id={id}
      name={id}
      label={label}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

function withProps(params, page) {
  let queries = []
  if (params.listConfigsQuery) {
    queries.push(
      graphql(Query[params.listConfigsQuery], {
        options: {
          fetchPolicy: 'cache-and-network',
        },
        props: props => {
          return {
            allConfigsloading: props.data.loading,
            allConfigs: props.data[params.listConfigsQuery] || [],
          }
        },
      }),
    )
  }

  if (params.idx) {
    // edit
    queries.push(
      graphql(Query[params.byIdQuery], {
        options: {
          fetchPolicy: 'cache-and-network',
          variables: { idx: params.idx },
        },
        props: props => {
          const results = { idx: params.idx, byIdQueryLoading: true }
          if (props.data[params.byIdQuery] !== undefined) {
            results.byIdQueryLoading = false
            results.obj = props.data[params.byIdQuery]
          }
          buildSubscriptionForDetailUserOrDoor(props, results, params)
          return results
        },
      }),
    )

    if (
      params.type !== 'schedule' &&
      params.type !== 'role' &&
      params.type !== 'dgroup'
    ) {
      queries.push(
        graphql(Query.listConfigurationsByTid, {
          options: {
            fetchPolicy: 'cache-and-network',
            variables: { tid: `${params.type}:${params.idx}` },
          },
          props: props => {
            return {
              configsLoading: props.data.loading,
              configs: props.data.listConfigurationsByTid || [],
            }
          },
        }),
      )
    }
  }

  if (params.event) {
    queries.push(buildEventSubscription(params))
  }

  return compose(...queries)(page)
}

function buildSubscriptionForDetailUserOrDoor(props, results, params) {
  if (params.type === 'door') {
    results.subscribeToUpdateController = () => {
      props.data.subscribeToMore(
        buildSubscription(
          'onUpdateController',
          'getDoorById',
          (door, item) => {
            return { ...door, ...item }
          },
          { sid: Auth.userProfile.tenantId },
        ),
      )
    }
  }
  if (params.type === 'user') {
    results.subscribeToUploadPhoto = () => {
      props.data.subscribeToMore(
        buildSubscription(
          'onCreateNotificationResizeImageSuccess',
          'getUserById',
          (user, data) => {
            if (user.getUserById && user.getUserById.idx === data.idx) {
              props.data.refetch()
            }
            return user
          },
          { idx: params.idx, sid: Auth.userProfile.tenantId },
        ),
      )
    }
  }
}

function getEditViewProps(page) {
  return {
    loading: page.state.loading,
    addOrUpdate: page.addOrUpdate,
    hideLoading: () => page.setState({ loading: false }),
    showLoading: () => page.setState({ loading: true }),
  }
}

const POSITIVE_FLOAT_PATTEN = /^\+?\d*(\.\d+)?$/
function getRelayTimeErrorMessage(item, relayTime, required) {
  if (!required && !relayTime) {
    return ''
  }
  relayTime = `${relayTime}`.trim()
  if (relayTime && POSITIVE_FLOAT_PATTEN.test(relayTime)) {
    item.relayTime = parseFloat(relayTime)
    return rangeValidation(item.relayTime, 0.5, 60)
  }
  return 'Please enter a number between 0.5 and 60'
}

function rangeValidation(val, min, max) {
  if (val < min || val > max) {
    return `Please enter a number between ${min} and ${max}`
  }
  return ''
}

function useWatchQuery(client, queryName, variables, callback, errorCallback) {
  const watchQuery = client
    .watchQuery({
      query: Query[queryName],
      variables,
      fetchPolicy: 'cache-and-network',
    })
    .subscribe({
      next: ({ loading, data }) => {
        if (data) {
          callback(data[queryName])
        }
        if (!loading) {
          watchQuery.unsubscribe()
        }
      },
      error: e => {
        watchQuery.unsubscribe()
        if (errorCallback) {
          errorCallback(e)
        }
        log.error(queryName, e)
      },
    })
}

function showMessageError(errorObj) {
  let messageError =
    (errorObj.graphQLErrors && errorObj.graphQLErrors[0]['message']) ||
    errorObj.message
  showToast({ message: messageError, className: 'toast-error' })
}

function toastWithMessage(message, closeTime = 2000, toastType = 'toast-info') {
  showToast({
    message,
    autoClose: closeTime,
    className: toastType,
  })
}

export function toashWarningMessage(message) {
  showToast({
    message: (
      <Box pad="small" gap="small">
        {message}
        <ControlButton plain onClick={() => window.open(DOCS_LINK.quickStart)}>
          <span className="btn-anchor-link">Click for more information</span>
        </ControlButton>
      </Box>
    ),
    className: 'toast-warn',
    autoClose: 2000,
  })
}

function buildEventSubscription(params) {
  const { limit, listEventsQuery } = params.event
  const QUERY_VARIABLES = {
    first: limit,
    idx: params.idx,
    ascOrder: false,
  }

  return graphql(Query[listEventsQuery], {
    options: {
      fetchPolicy: 'cache-and-network',
      variables: QUERY_VARIABLES,
    },
    props: props => {
      return {
        idx: params.idx,
        items: props.data[listEventsQuery] && props.data[listEventsQuery].items,
        nextToken:
          props.data[listEventsQuery] && props.data[listEventsQuery].nextToken,
        refetch: props.data.refetch,
        onLoadMore: (nextToken, ascOrder) => {
          QUERY_VARIABLES.ascOrder = ascOrder
          props.data.fetchMore({
            query: Query[listEventsQuery],
            variables: {
              ...QUERY_VARIABLES,
              after: nextToken,
              ascOrder,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const moreItems = fetchMoreResult[listEventsQuery].items.filter(
                item => {
                  const existed = previousResult[listEventsQuery].items.some(
                    e => {
                      return e.uuid === item.uuid
                    },
                  )
                  return !existed
                },
              )
              return {
                [listEventsQuery]: {
                  ...fetchMoreResult[listEventsQuery],
                  items: [
                    ...previousResult[listEventsQuery].items,
                    ...moreItems,
                  ],
                },
              }
            },
          })
        },
        subscribeToMoreEvents: (idx, fieldName, action) => {
          props.data.subscribeToMore({
            document: Subscription[action],
            variables: {
              sid: Auth.userProfile.tenantId,
              [fieldName]: idx,
            },
            updateQuery: (
              prev,
              {
                subscriptionData: {
                  data: { onCreateEvent },
                },
              },
            ) => {
              let items = [...prev[listEventsQuery].items]
              if (!items || items.length === 0) {
                items = [onCreateEvent]
              } else {
                const existed = items.some(
                  event => event.uuid === onCreateEvent.uuid,
                )
                if (!existed && !QUERY_VARIABLES.ascOrder) {
                  // if new event time < old event time
                  if (items[0]['time'] > onCreateEvent['time']) {
                    items = buildCreateEventSubscriptionResult(
                      items,
                      onCreateEvent,
                    )
                  } else {
                    items = [onCreateEvent, ...items]
                  }
                }
              }
              return {
                ...prev,
                [listEventsQuery]: {
                  ...prev[listEventsQuery],
                  items,
                },
              }
            },
          })
        },
      }
    },
  })
}

const preventCharacterKey = (e, value) => {
  !(
    (e.keyCode >= 48 && e.keyCode <= 57) ||
    (e.keyCode >= 96 && e.keyCode <= 105)
  ) &&
    e.keyCode !== 8 &&
    e.keyCode !== 46 &&
    (!value ||
      value.includes('.') ||
      (e.keyCode !== 190 && e.keyCode !== 110)) &&
    e.preventDefault()
}

export function setupScheduleValue(schedule) {
  if (!schedule) {
    return EMPTY_OPTION
  }
  return { value: schedule, label: schedule.name }
}

const hasVerticalScroll = node => {
  if (!node) {
    if (window.innerHeight) {
      return document.body.offsetHeight > window.innerHeight
    }
    return (
      document.documentElement.scrollHeight >
        document.documentElement.offsetHeight ||
      document.body.scrollHeight > document.body.offsetHeight
    )
  }
  return node.scrollHeight > node.offsetHeight
}

function getViewContentBackground() {
  let pathname = window.location.pathname
  const arrPath = pathname.split('/')
  let whiteColor = true
  if (arrPath.length > 0) {
    let name = arrPath[arrPath.length - 1]

    // list of cards
    if (
      name === 'users' ||
      name === 'doors' ||
      name === 'groups' ||
      name === 'rules' ||
      name === 'schedules' ||
      name === 'alarms' ||
      name === 'events' ||
      name === 'integrations'
    ) {
      whiteColor = false
    }

    if (arrPath.length > 1) {
      let _events = arrPath[arrPath.length - 2]
      let _view = arrPath[arrPath.length - 1]
      if (_events === 'events' && _view === 'view') {
        whiteColor = false
      }
    }
  }

  let backgroundColor = whiteColor ? 'white' : '#dcdcdc'

  return backgroundColor
}

function checkFloatingButton() {
  let pathname = window.location.pathname
  const arrPath = pathname.split('/')
  let hasFloatingButton = false
  if (arrPath.length > 0) {
    let name = arrPath[arrPath.length - 1]

    // list of cards
    if (
      name === 'users' ||
      name === 'roles' ||
      name === 'user_tags' ||
      name === 'doors' ||
      name === 'groups' ||
      name === 'door_tags' ||
      name === 'rules' ||
      name === 'schedules' ||
      name === 'integrations'
    ) {
      hasFloatingButton = true
    }
  }

  return hasFloatingButton
}

function getSerialFromQRCode(qrCode) {
  let _serial = qrCode
  if (qrCode) {
    _serial = qrCode.replace(`${APP_PACKAGE_NAME}://serial/`, '')
  }

  return _serial
}

function validateURL(URL) {
  const regex = new RegExp(
    '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
  )
  return regex.test(URL)
}

export {
  makeTextInputField,
  makeTextAreaField,
  makeTextInputFieldDisabled,
  makeSelectField,
  makeCustomSelectField,
  makeSelectMultipleField,
  EMPTY_OPTION,
  filterConfigs,
  makeRadioField,
  filterConfigOptions,
  getEditViewProps,
  getRelayTimeErrorMessage,
  withProps,
  useWatchQuery,
  showMessageError,
  toastWithMessage,
  preventCharacterKey,
  hasVerticalScroll,
  getViewContentBackground,
  checkFloatingButton,
  getSerialFromQRCode,
  validateURL,
}
