import 'whatwg-fetch'
import 'raf/polyfill'
import './scss/index.scss'
import 'ulog'

import * as serviceWorker from './serviceWorker'

import App from './js/App'
import React from 'react'
import ReactDOM from 'react-dom'
import { prodDomain } from './js/StaticConfig'
import { polyfill as promisePolyfill } from 'es6-promise'

// import AWSAppSyncClient from 'aws-appsync'
// import { ApolloProvider } from 'react-apollo'
// import StaticConfig from './js/StaticConfig.js'

/**
 * config ulog
 */
if (
  process.env.NODE_ENV === 'production' &&
  window.location.hostname === prodDomain
) {
  localStorage.setItem('log', '0') // set log level none for ulog
} else {
  localStorage.setItem('log', '7') // set log level all for ulog
}

promisePolyfill()

/**
 * disable console log in production
 */
if (
  process.env.NODE_ENV === 'production' &&
  window.location.hostname === prodDomain
) {
  console.log = () => {}
  console.info = () => {}
  console.error = () => {}
  console.warn = () => {}
  console.debug = () => {}
  console.trace = () => {}
}

// const element = document.getElementById('content')
ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
