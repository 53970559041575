import Auth, { getToken } from '../auth/Auth'
import React, { Component } from 'react'

import { Auth0Context } from '../auth/auth0-context'
import { Capacitor } from '@capacitor/core'
import LoadingIndicator from '../components/elements/Loading'
import anylogger from 'anylogger'
import { connect } from 'react-redux'

const log = anylogger('LoginCallBack')

class LoginCallBack extends Component {
  static contextType = Auth0Context

  componentDidMount() {
    log(
      '---> LoginCallBack --> window.location.search: ',
      window.location.search,
    )

    if (Capacitor.isNative) {
      if (window.location.search.includes('code=')) {
        log('---> LoginCallBack --> code=')
        return this.handleRedirectCallback()
      } else if (window.location.search.includes('error=')) {
        log('---> LoginCallBack --> error=')
        const query = new URLSearchParams(window.location.search)
        let errorDescription = query.get('error_description') ?? ''
        log('---> LoginCallBack --> errorDescription: ', errorDescription)

        Auth.getInstance().handleMobileError(
          errorDescription,
          this.props.dispatch,
          true,
        )
      }
    } else {
      if (/access_token|id_token|error/.test(window.location.hash)) {
        Auth.getInstance().handleAuthentication(this.props.dispatch)
      }
    }
  }

  handleRedirectCallback = async () => {
    this.setState({ isLoading: true })

    const auth0Context = this.context
    await auth0Context?.handleRedirectCallback()
    const userInfo = await auth0Context?.getUser()
    let accessToken = await getToken(auth0Context, false)
    log(
      '---> LoginCallBack --> handleRedirectCallback -> getToken: ',
      accessToken,
    )

    if (userInfo && accessToken) {
      Auth.getInstance().setSessionSpa(
        accessToken,
        userInfo,
        this.props.dispatch,
      )
    }
  }

  render() {
    return (
      <LoadingIndicator
        callback
        full
        message="Identiv Nox loading..."
        width="200"
      />
    )
  }
}

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapDispatchToProps)(LoginCallBack)
