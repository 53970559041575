import * as Sentry from '@sentry/react'

import { NEED_TO_RELOAD, NEED_TO_SUBSCRIBE_ALARM } from '../actions'

import Auth from '../auth/Auth'
import anylogger from 'anylogger'
import { showMessageError } from '../components/pages/Util'

const log = anylogger('Request')

export default async function(query, objectQuery, mutation) {
  const queryObject = {
    ...objectQuery,
    [mutation ? 'mutation' : 'query']: query,
  }

  const client = await Auth.getInstance().getApolloClient()
  try {
    const resp = await client[mutation ? 'mutate' : 'query'](queryObject)
    return resp.data
  } catch (error) {
    log.error(error)
    showMessageError(error)
    // api reqest error
    Sentry.captureException(error)
    return null
  }
}

export async function subscription(subscription, variables) {
  const client = await Auth.getInstance().getApolloClient()

  if (!client) {
    return null
  }

  try {
    return client.subscribe({
      variables,
      query: subscription,
      fetchPolicy: 'network-only',
    })
  } catch (e) {
    showMessageError(e)
    // api reqest error
    Sentry.captureException(e)
    return null
  }
}

export const subscribeAlarmObject = {
  _instant: null,
  _status: false,
  subscribeGlobal(sb, variables) {
    if (!this._instant && !this._status) {
      this._status = true
      subscription(sb, variables).then(subs => {
        if (subs) {
          this._instant = subs.subscribe(
            ({ data }) => {
              log('---> subscribeAlarmObject --> variables: ', variables)
              log('---> subscribeAlarmObject --> data: ', data)
              if (data.onMutationAlarm) {
                Auth.getInstance()
                  .getUserLoginInfos()
                  .then(user => {
                    if (window.nox.store.dispatch) {
                      window.nox.store.dispatch({
                        type: 'UPDATE_ALARM',
                        tenantHost: user.tenantHost,
                        countActiveAlarms: user.countActiveAlarms,
                      })
                    }
                  })
              }
            },
            err => {
              log.error('---> subscribeAlarmObject --> err: ', err)

              let _errorMessage = err.errorMessage // AMQJS0008I Socket closed.
              if (_errorMessage && _errorMessage.includes('AMQJS0008I')) {
                log.error(
                  '---> subscribeAlarmObject --> AMQJS0008I Socket closed.',
                )

                if (window.nox.store.dispatch) {
                  window.nox.store.dispatch({
                    type: NEED_TO_RELOAD,
                    needToReload: true,
                  })
                }
              }
            },
            () => log('---> subscribeAlarmObject --> complete'),
          )
        }
      })
    }
  },
  updateSubscribe(sb, variables) {
    this.unsubscribe()
    this.subscribeGlobal(sb, variables)

    if (window.nox.store.dispatch) {
      window.nox.store.dispatch({
        type: NEED_TO_SUBSCRIBE_ALARM,
        needToSubscribeAlarm: true,
      })
    }
  },
  unsubscribe() {
    if (this._instant && this._status) {
      log('---> subscribeAlarmObject --> unsubscribe')
      this._instant.unsubscribe()
      this._instant = null
      this._status = false
    }
  },
}
