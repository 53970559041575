import * as Sentry from '@sentry/react'

import CONSTANTS, { KEYS } from './appsettings/constants'
import { IntlProvider, addLocaleData } from 'react-intl'
import React, { useState } from 'react'

import AppMobileService from './service/AppMobileService'
import Auth from './auth/Auth'
import { Auth0Provider } from './auth/auth0-context'
import { BrowserTracing } from '@sentry/tracing'
import ErrorBoundary from './hocs/ErrorBoundary'
import { KEYS_FLAG } from './components/Flag'
import Main from './components/Main'
import { PersistGate } from 'redux-persist5/integration/react'
// import { getCurrentLocale, getLocaleData } from 'grommet/utils/Locale'
import { Provider } from 'react-redux'
import PushNnotificationService from './service/PushNotificationService'
import { SESSION_LOAD } from './actions'
import configureStore from './store'
import { createTheme } from '@material-ui/core/styles'
import en from 'react-intl/locale-data/en'
import { getNodeEnv } from '../js/StaticConfig'

const auth = Auth.getInstance()
const { store, persistor } = configureStore()
const appMobileService = new AppMobileService()
const pushService = new PushNnotificationService()

// const locale = getCurrentLocale()
addLocaleData(en)

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  release: process.env.REACT_APP_GIT_COMMIT_SHA || 'unknown',
  environment: getNodeEnv(),
})

// let messages
// try {
//   messages = require(`./messages/${locale}`)
// } catch (e) {
//   messages = require('./messages/en-US')
// }
// const localeData = getLocaleData(messages, locale)

let path = window.location.pathname
function initialize() {
  appMobileService.handleNavigation()
  return dispatch => {
    if (auth.isAuthenticated() || path === `${CONSTANTS.basename}/callback`) {
      pushService.initPushNotification()
      dispatch({ type: SESSION_LOAD })
    } else {
      let baseRoute = CONSTANTS.basename
      if (
        ![
          '/',
          baseRoute,
          `${baseRoute}/`,
          `${baseRoute}/start`,
          '/start',
        ].includes(path)
      ) {
        localStorage.setItem(KEYS.recentUrlKey, path)
      }
    }
  }
}
if (path.includes('/v2')) {
  path = path.replace('/v2', '')
  window.location.replace(`https://${window.location.hostname}${path}`)
}
if (
  path !== `${CONSTANTS.basename}/login` &&
  path !== `${CONSTANTS.basename}/welcome`
) {
  store.dispatch(initialize(path))
}

// set global store
window.nox = {
  store,
}

export const theme = createTheme({
  typography: {
    fontFamily: ['Work Sans'].join(','),
  },
  overrides: {
    MuiFab: {
      root: {
        width: 48,
        height: 48,
      },
      primary: {
        width: 48,
        height: 48,
        backgroundColor: '#3D4C53',
        '&:hover': {
          width: 48,
          height: 48,
          backgroundColor: '#3D4C53',
          '@media (hover: none)': {
            backgroundColor: '#3D4C53',
          },
        },
      },
    },
  },
})

export const FlagContext = React.createContext([{}, () => {}])

function App() {
  const [flagState, setFlagState] = useState({
    [KEYS_FLAG.themeV1]: false,
    [KEYS_FLAG.themeV2]: true, // default load new theme
    [KEYS_FLAG.addDoorV1]: true,
    [KEYS_FLAG.addDoorV2]: false,
  })

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider locale="en">
          <ErrorBoundary>
            <Auth0Provider>
              <FlagContext.Provider value={[flagState, setFlagState]}>
                <Main />
              </FlagContext.Provider>
            </Auth0Provider>
          </ErrorBoundary>
        </IntlProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
