import { Button, Text } from 'grommet'

import React from 'react'

export default function ControlButton(props) {
  const {
    label,
    onClick,
    borderWidth = 0,
    style,
    isCenter = true,
    disabled = false,
    ...params
  } = props
  const styles = {
    borderWidth,
    borderRadius: '4px',
    padding: '10px 15px',
    ...style,
  }
  if (isCenter) styles.width = '100%'
  return (
    <div>
      <Button
        {...params}
        disabled={disabled}
        style={styles}
        label={
          <Text weight="bold" size="16px" style={{ lineHeight: '18px' }}>
            {label}
          </Text>
        }
        onClick={onClick}
      />
    </div>
  )
}
