import * as Sentry from '@sentry/react'

import Auth, { getCredetial } from '../auth/Auth'
import { DEFAULT_HOME_PAGE, KEYS } from '../appsettings/constants'
import { Mutation, Query, Subscription } from 'nox-api'
import {
  browserName,
  deviceType,
  fullBrowserVersion,
  getUA,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from 'react-device-detect'
import { lowerCase, upperCase, upperFirst } from 'lodash'

import anylogger from 'anylogger'
import history from '../components/history'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import showToast from '../components/elements/Toast'

const log = anylogger('ServiceUtils')

const publicIp = require('public-ip')

export const EVENT_OPERATION = {
  EQUAL: 'EQUAL',
  NOT_EQUAL: 'NOT_EQUAL',
}

export const dateName = {
  MONDAY: 'mon',
  TUESDAY: 'tue',
  WEDNESDAY: 'wed',
  THURSDAY: 'thu',
  FRIDAY: 'fri',
  SATURDAY: 'sat',
  SUNDAY: 'sun',
}

export const KvsStatus = {
  ACTIVE: 'ACTIVE',
  CREATING: 'CREATING',
  DELETING: 'DELETING',
  DISABLED: 'DISABLED',
  CREATION_PENDING: 'CREATION_PENDING',
  CREATION_FAILED: 'CREATION_FAILED',
  DELETION_PENDING: 'DELETION_PENDING',
  DELETION_FAILED: 'DELETION_FAILED',
}

export const DOOR_STATUS = {
  LOCKED: 'LOCKED',
  UNLOCKED: 'UNLOCKED',
  SCHEDULE: 'SCHEDULE',
}

export const CONTROLLER_REQUEST_TYPE = {
  MOMENTARY: 'momentary',
  GETCAMERA: 'get-cameras',
  RTSPURIS: 'get-rtsp-uris',
}

export const CONTROLLER_STATUS = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
}

export const KinesisVideoPlaybackMode = {
  LIVE: 'LIVE',
  LIVE_REPLAY: 'LIVE_REPLAY',
  ON_DEMAND: 'ON_DEMAND',
}

export const USER_STATE = {
  DEFAULT: 'DEFAULT',
  SIGNED_UP: 'SIGNED_UP',
  ACTIVE_FAIL: 'ACTIVE_FAIL',
  UNCONFIRMED: 'UNCONFIRMED',
}
export const USER_RIGHT = {
  CARD: 'CARD',
  MOBILE: 'MOBILE',
  ADMIN: 'ADMIN',
}

export const FEATURES = {
  NFC_TAG: 'nfc_tag',
  ANALYTICS: 'analytics',
  VIDEO: 'video',
  TOKEN: 'token',
  MAP: 'map',
}

export const ALARM_STATUS = {
  Active: 'Active',
  Closed: 'Closed',
  Snoozed: 'Snoozed',
}

export const SomeThingWentWrongMessage =
  'There is no live video from this camera. If you have just configured this camera it can take up to a minute to start streaming. This can also be caused if your controller is offline or restarting.'

export const TITLE = {
  '/events/integrations': 'Integrations',
  '/events/archivedvideo': 'Archived Video',
  '/events/dashboard': 'Dashboard',
  '/events/alarms': 'Alarms',
  '/events/view': 'Events',
  '/events/tags': 'Tags',
  '/events/captured-videos': 'Captured Video',
  '/search': 'Search',
  '/config/doors/add': 'New Door',
  '/config/doors': 'Doors',
  '/config/groups': 'Groups',
  '/config/groups/add': 'Add Door Group',
  '/config/door_tags': 'Door Tags',
  '/config/users/add': 'New User',
  '/config/users': 'Users',
  '/config/roles/add': 'Add User Role',
  '/config/roles': 'Roles',
  '/config/user_tags': 'User Tags',
  '/config/rules/add': 'New Rule',
  '/config/rules': 'Rules',
  '/config/schedules/add': 'New Schedule',
  '/config/schedules': 'Schedules',
  '/tenants': 'Tenants',
  '/users': 'Users',
  '/config/settings': 'Settings',
}

export const DEALER_URLS = ['/tenants', '/users']

export const DOCS_LINK = {
  integrations: 'https://docs.identivnox.com/en/article/integrations-1o8k7e2/',
  doors: 'https://docs.identivnox.com/en/article/door-configuration-cbbn32/',
  schedules: 'https://docs.identivnox.com/en/article/schedules-140gsuw/',
  users: 'https://docs.identivnox.com/en/article/user-management-mz80uf/',
  'access-rules': 'https://docs.identivnox.com/en/article/access-rules-dwbvf7/',
  quickStart:
    'https://docs.identivnox.com/en/article/quick-start-guide-12etx6d/',
  update: 'https://docs.identivnox.com/en/article/update-history-1v0r77/',
  events: 'https://docs.identivnox.com/en/article/events-nck66i/',
  rules: 'https://docs.identivnox.com/en/article/access-rules-dwbvf7/',
  faq:
    'https://docs.identivnox.com/en/article/frequently-asked-questions-1ldckyh/',
  dashboard: 'https://docs.identivnox.com/en/article/dashboard-4u522r/',
  capturedVideo:
    'https://docs.identivnox.com/en/article/video-and-camera-configuration-jq9ckl/',
  doorsMap: 'https://docs.identivnox.com/en/article/door-maps-iwhi1b/',
}

export const APPLINK = {
  android: 'https://play.google.com/store/apps/details?id=com.nox.identiv',
  ios: isMobile
    ? 'itms-apps://itunes.apple.com/app/apple-store/id1442038523?mt=8'
    : 'https://apps.apple.com/us/app/identiv-nox/id1442038523?ls=1',
}

export const MESSAGES_NEED_TO_MAP = [
  'Cannot publish to IoT.',
  'Cannot connect or subscribe to IoT.',
]

export const sleep = (delay, value) =>
  new Promise(resolve => setTimeout(resolve, delay, value))

export const FILTER_FIELDS = ['type', 'doorId', 'userId']

export const typeOfPopupEditLink = ['door_tag', 'user_tag', 'integration']

export const TokenStatus = {
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
}

export const TIME_FORMAT = 'HH:mm:ss.SSS L'
export const DAY_FORMAT = 'yyyy-MM-dd HH:mm:ss'

export const BAD_CREDENTIALS = ['000003FFFFFFFFFFFFF']
export function getKeyOfDateName(value) {
  return Object.keys(dateName)
    .filter(k => dateName[k] === value)
    .pop()
}

export function buildDataForOneSearchResult(item) {
  if (!item) return {}
  let url = ''
  let type = `${item.type}s`
  const splitUrl = ['user_role', 'door_group', 'access_rule']
  if (splitUrl.indexOf(item.type) !== -1) {
    type = type.split('_')[1]
  }

  if (typeOfPopupEditLink.indexOf(item.type) !== -1) {
    url = `${type}`
  } else {
    url = `${type}/${item.idx}`
  }

  return {
    url,
    editLayerActive: typeOfPopupEditLink.indexOf(item.type) !== -1,
  }
}

export function validEmail(str) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    `${str}`.toLowerCase(),
  )
}

export function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // fragment locator
  return !!pattern.test(str)
}

export function isValidSlackURL(str) {
  return (
    !!RegExp(
      '^https:\\/\\/hooks\\.slack\\.com/services\\/T[a-zA-Z0-9]{8}\\/B[a-zA-Z0-9]{8,10}\\/[a-zA-Z0-9]{24}$',
      'i',
    ).test(str) ||
    !!RegExp('^https:\\/\\/discord\\.com\\/.+\\/slack$', 'i').test(str)
  )
}

export async function onClickSearch(
  inputKey,
  typeQuery = '',
  filters = [],
  moreFilter, // objec {hasGroup,hasLink}
  paging, // {page: 0, size: 50} default
) {
  try {
    const client = await Auth.getInstance().getApolloClient()
    let variables = { inputKey, typeQuery, filters }
    if (moreFilter) {
      variables.moreFilter = moreFilter
    } else {
      variables.moreFilter = null
    }

    if (paging) {
      if (!paging.size) {
        paging.size = 50
      }
      variables.paging = paging
    } else {
      variables.paging = {
        page: 0,
        size: 50,
      }
    }

    const {
      data: { esSearch },
    } = await client.query({
      query: Query.esSearch,
      fetchPolicy: 'network-only',
      variables,
    })

    return esSearch
  } catch (error) {
    log.error('Error: ', error)
    throw error
  }
}

export function saveSearch(text, userId = 'ANONYMOUS') {
  const searchText = localStorage.getItem(userId) || '[]'
  let arr = JSON.parse(searchText)
  if (text && !arr.includes(text)) arr.push(text)
  if (arr.length === 11) {
    arr = arr.slice(1, arr.length)
  }
  localStorage.setItem(userId, JSON.stringify(arr))
  return arr
}

export function buildTitleName(pathName) {
  let name = TITLE[pathName] || pathName
  return name ? `${name} | Identiv Nox` : ''
}

function getTime(date) {
  return moment
    .utc(date)
    .local()
    .format('HH')
}

function getDateAndMonth(date) {
  const day = moment.utc(date)
  if (!day.isValid()) {
    return date
  }
  return day.format('MM/DD')
}

export function setupDataForChart(metric) {
  return setupValueForChart(metric.values, metric.countType)
}

function setupValueForChart(data, type) {
  const labels = []
  const values = []
  for (const item of data) {
    labels.push(
      lowerCase(type) === 'day'
        ? getDateAndMonth(item.name)
        : getTime(item.name),
    )
    values.push(item.value)
  }
  return { labels, values }
}

export function buildTitleText(txt) {
  return upperFirst(lowerCase(upperCase(txt)))
}

export function buildMetricLabel(metric) {
  return `${metric.count} ${buildTitleText(metric.countType)}s`
}

export function buildMetricOptions(metrics) {
  const options = []
  metrics.forEach(metric => {
    const key = buildMetricLabel(metric)
    if (!options.find(item => item.label === key)) {
      options.push({
        value: key,
        label: key,
      })
    }
  })
  return options
}

export const listenResizeAndUpdateState = (setState, fieldName = 'width') => {
  return () => {
    const { innerWidth } = window
    setState({ [fieldName]: innerWidth })
  }
}

const defaultValue = title => [
  {
    title,
    values: [],
    data: { labels: [], values: [] },
  },
]
const getDefaultValue = title => ({
  '7 Days': defaultValue(title),
  '24 Hours': defaultValue(title),
})
export const defaultCharts = {
  USER: getDefaultValue('User'),
  DOOR: getDefaultValue('Door'),
  EVENT: getDefaultValue('Event'),
  OFFLINE_STATUS: getDefaultValue('Door offline'),
  ACCESS: getDefaultValue('Access allowed'),
}

export async function sendToControllerMessageWithType(
  item = { controllerUuid: '', controllerName: '' },
  type = 'momentary',
  callback,
  payload,
) {
  const { user, client } = await getAuthDataInfos()
  if (!user || !user.id || !user.name) {
    throw new Error('User info is missing', JSON.stringify(user))
  }

  if (!payload) payload = JSON.stringify({ sourceType: 'WEB' })
  let inputObj = {
    type: type,
    controllerId: item.controllerUuid,
    payload: payload,
  }
  if (type === CONTROLLER_REQUEST_TYPE.GETCAMERA) {
    inputObj = {
      type: type,
      controllerId: item.controllerUuid,
    }
  }

  client
    .mutate({
      mutation: Mutation.requestController,
      variables: {
        input: inputObj,
      },
    })
    .then(response => {
      callback({
        status: 'OK',
        data: response.data.requestController,
      })
    })
    .catch(error => {
      if (type !== CONTROLLER_REQUEST_TYPE.GETCAMERA) {
        showToast({
          message: 'Failed to request access',
          type: 'warn',
          className: 'toast-error',
        })
      }
      callback({
        status: 'FAILED',
        error: error,
      })
    })
}

// TODO Auth.userProfile maybe null
export async function getAuthDataInfos() {
  const authInstance = Auth.getInstance()
  const user = Auth.userProfile
  const client = await authInstance.getApolloClient()
  return {
    authInstance,
    user,
    client,
  }
}

export async function sendToControllerMessage(
  item = { controllerUuid: '', doorName: '' },
  type = 'momentary',
  callback,
) {
  const { client, user } = await getAuthDataInfos()
  if (!user || !user.id || !user.name) {
    throw new Error('User info is missing', JSON.stringify(user))
  }
  const now = moment()
  client
    .mutate({
      mutation: Mutation.requestController,
      variables: {
        input: {
          type: type,
          controllerId: item.controllerUuid,
          payload: JSON.stringify({ sourceType: 'WEB' }),
        },
      },
    })
    .then(response => {
      log('response: ', response)
      log(
        now.utc().format(),
        item.doorName,
        item.controllerUuid,
        ', spent',
        moment().valueOf() - now.valueOf(),
        'ms',
      )
      callback({
        status: 'OK',
        data: response.data.requestController,
      })
    })
    .catch(error => {
      log.error('Error: ', error)
      showToast({
        message: 'Failed to request access',
        type: 'warn',
        className: 'toast-error',
      })
      callback({
        status: 'FAILED',
        error: error,
      })
    })
}

export function processCBControllerData(cbControllerData) {
  let dataObj = JSON.parse(cbControllerData.data)
  let errMsg = ''
  let statusMsg = 'TIMEOUT'
  if (Object.prototype.hasOwnProperty.call(dataObj, 'result')) {
    statusMsg = dataObj.result
  } else {
    errMsg = dataObj.errMsg
  }
  return {
    statusMsg,
    errMsg,
  }
}

export function messageMomentaryAccess(res, item, errMsg) {
  if (!res) return
  const resp = {
    message: '',
    className: 'toast-success',
    gtmAction: 'request-access-success',
  }
  // errMsg from controller response
  if (errMsg) {
    resp.message = errMsg
    resp.className = 'toast-error'
    resp.gtmAction = 'request-access-fail'
    //should not show the raw message from the lambda to users
    showToast({
      message: 'Something went wrong, please try again!',
      className: resp.className,
    })
    return
  }
  switch (res) {
    case 'ALLOWED':
      resp.message = `Access granted to "${item.name}"`
      break
    case 'UNLOCKED':
      resp.message = `"${item.name}" is already unlocked`
      resp.gtmAction = 'request-access-unlock'
      break
    case 'DENIED':
      resp.message = `Access denied to "${item.name}"`
      resp.gtmAction = 'request-access-deny'
      resp.className = 'toast-warn'
      break
    case 'TIMEOUT':
      resp.message = `Access timeout to "${item.name}"`
      resp.gtmAction = 'request-access-timeout'
      resp.className = 'toast-warn'
      if (item.controller.status === CONTROLLER_STATUS.OFFLINE) {
        resp.message =
          'Communication timeout (controller appears to be offline)'
      }
      break
    default:
      break
  }
  showToast({ message: resp.message, className: resp.className })
}

export function getProcessMsgError(errorMsg) {
  let msgUpdated = errorMsg
  if (MESSAGES_NEED_TO_MAP.includes(errorMsg)) {
    msgUpdated = 'Something went wrong, please try again!'
  }
  return msgUpdated
}

export function buildDataForEditPage(data, subscribeToMoreEvents) {
  const {
    idx,
    obj,
    allConfigs,
    configs,
    loading,
    items,
    refetch,
    nextToken,
    onLoadMore,
    allConfigsloading,
    configsLoading,
  } = data

  return {
    params: {
      idx,
      obj,
      allConfigs,
      configs,
      loading,
      allConfigsloading,
      configsLoading,
    },
    eventParams: {
      items,
      refetch,
      nextToken,
      onLoadMore,
      subscribeToMoreEvents,
    },
  }
}

export function buildSubscription(
  subscriptionDocument,
  query,
  buildItem,
  variables,
) {
  return {
    document: Subscription[subscriptionDocument],
    variables,
    updateQuery: (prev, { subscriptionData: { data } }) => {
      if (!prev) return null
      const items =
        prev[query] && prev[query].items
          ? prev[query].items
          : [data[subscriptionDocument]]
      if (
        (query === 'getDoorById' || query === 'getUserById') &&
        items.length === 1
      ) {
        return buildItem(prev, data[subscriptionDocument])
      }

      return Object.assign({}, prev, {
        [query]: {
          nextToken: prev[query] ? prev[query].nextToken : null,
          items: [
            ...items.map(item => buildItem(item, data[subscriptionDocument])),
          ],
          __typename: prev[query] ? prev[query].__typename : null,
        },
      })
    },
    onError: error => {
      log.error(`---> subscribe --> ${subscriptionDocument} --> error:`)
      log.error(error)
    },
  }
}

export function buildActionForBackButton(isBackToList = false) {
  let backHistory = JSON.parse(localStorage.getItem('history'))
  // save if it isn't exist
  if (!backHistory) {
    localStorage.setItem('history', JSON.stringify(history.location))
    backHistory = history.location
  }
  if (
    isBackToList ||
    !history.location.key ||
    history.location.key === backHistory.key ||
    history.length === 1
  ) {
    let path = DEFAULT_HOME_PAGE
    const paths = window.location.pathname.split('/')
    if (paths.length === 4 && paths[3]) {
      path = `/${paths[1]}/${paths[2]}`
    }
    return history.push(path)
  }

  return history.goBack()
}

export function saveErrorToDebug(error) {
  localStorage.setItem('root_cause', [
    localStorage.getItem('root_cause') || '',
    new Date(),
    error,
    JSON.stringify(error),
  ])
}

export const buildStartAndEndTime = (time, subSecond = 0, endSecond = 0) => {
  if (!moment(time).isValid()) {
    return {}
  }
  const startTime = subSecond
    ? moment(time)
        .subtract(subSecond, 's')
        .unix()
    : moment(time).unix()
  return {
    startTime,
    endTime: endSecond ? startTime + endSecond : null,
  }
}

export async function getStreamUrl(options) {
  const {
    streamName,
    startTime,
    endTime,
    PlaybackMode = KinesisVideoPlaybackMode.ON_DEMAND,
  } = options
  if (!streamName) throw new Error('streamName required')
  const kinesisVideoArchivedContent = await buildKvsArchived({
    ...options,
    APIName: 'GET_HLS_STREAMING_SESSION_URL',
  })
  const streamSessionOptions = {
    ContainerFormat: 'FRAGMENTED_MP4',
    DiscontinuityMode: 'ALWAYS',
    StreamName: streamName,
    PlaybackMode,
    HLSFragmentSelector: {
      FragmentSelectorType: 'SERVER_TIMESTAMP',
    },
    Expires: 36000,
  }

  if (PlaybackMode !== KinesisVideoPlaybackMode.LIVE) {
    if (!startTime) throw new Error('startTime required')
    streamSessionOptions.HLSFragmentSelector.TimestampRange = {
      StartTimestamp: startTime,
    }
    if (endTime) {
      streamSessionOptions.HLSFragmentSelector.TimestampRange.EndTimestamp = endTime
    }
  }

  const streamSession = await kinesisVideoArchivedContent
    .getHLSStreamingSessionURL(streamSessionOptions)
    .promise()

  return streamSession.HLSStreamingSessionURL
}

async function buildKvsArchived(options) {
  const { streamName, region, APIName } = options

  // dynamic loading
  const [
    { default: AWS },
    { default: AwsKinesisVideo },
    { default: AwsKinesisVideoArchivedMedia },
    credential,
  ] = await Promise.all([
    import('../service/AWS'),
    import('../service/AwsKinesisVideo'),
    import('../service/AwsKinesisVideoArchivedMedia'),
    new Promise((resolve, reject) => {
      getCredetial((err, cred) => {
        if (err) return reject(err)
        resolve(cred)
      })
    }),
  ])

  const option = {
    region: region || 'us-west-2',
    credentials: new AWS.Credentials({
      accessKeyId: credential.AccessKeyId,
      sessionToken: credential.SessionToken,
      secretAccessKey: credential.SecretKey,
    }),
    apiVersion: '2017-09-30',
  }

  const kinesisVideoArchivedContent = new AwsKinesisVideoArchivedMedia({
    ...option,
    httpOptions: {
      timeout: 300000,
    },
  })
  const kinesisVideo = new AwsKinesisVideo(option)
  const resp = await kinesisVideo
    .getDataEndpoint({
      StreamName: streamName,
      APIName,
    })
    .promise()

  kinesisVideoArchivedContent.endpoint = new AWS.Endpoint(resp.DataEndpoint)
  return kinesisVideoArchivedContent
}

export function getClip(options, cb) {
  const { streamName, startTime, endTime } = options
  if (!streamName) return cb(new Error('streamName required'))
  if (endTime < startTime) return cb(new Error('time input invalid'))
  buildKvsArchived({ ...options, APIName: 'GET_CLIP' })
    .then(kinesisVideoArchivedContent => {
      kinesisVideoArchivedContent.getClip(
        {
          ClipFragmentSelector: {
            FragmentSelectorType: 'SERVER_TIMESTAMP',
            TimestampRange: {
              EndTimestamp: endTime,
              StartTimestamp: startTime,
            },
          },
          StreamName: streamName,
        },
        cb,
      )
    })
    .catch(Sentry.captureException)
}

export function capitalizeFirstLetter(text) {
  if (!text) return text
  let strings = `${text}`.toLowerCase().split('_')
  strings = strings.map(str => {
    if (!str) return str
    return str.charAt(0).toUpperCase() + str.slice(1)
  })
  return strings.join(' ')
}

export function formatTime(time) {
  return moment(time).isSame(moment(), 'day')
    ? moment(time).format('HH:mm:ss.SSS [today]')
    : moment(time).format('HH:mm:ss.SSS L')
}

export function formatTimeShort(time) {
  return moment(time).isSame(moment(), 'day')
    ? moment(time).format('HH:mm:ss [today]')
    : moment(time).format('HH:mm:ss L')
}

export function compareData(curProps, prevProps) {
  const fields = ['obj', 'configs', 'allConfigs']
  return !fields.find(field => curProps[field] !== prevProps[field])
}

export function initDataForDetailPage(props, processDoorData) {
  if (!props.idx) {
    processDoorData({}, props.allConfigs, [])
  } else if (props.obj) {
    processDoorData(props.obj, props.allConfigs, props.configs)
  } else {
    processDoorData({}, props.allConfigs, props.configs)
  }
}

export function buildCreateEventSubscriptionResult(oldItems, onCreateEvent) {
  let items = [...oldItems]
  for (let i = 0; i < items.length; i++) {
    if (items[i].time < onCreateEvent.time) {
      items.splice(i, 0, onCreateEvent)
      break
    }
  }

  return items
}

export function getCredentialFromCredId(credential) {
  return credential.includes(':')
    ? credential.substring(credential.indexOf(':') + 1)
    : credential
}

export function isExistedFeature(feature) {
  const features = localStorage.getItem('features') || ''
  return features.includes(feature)
}

// kvs save video only 5 days
export function isValidDayForKvs(eventTime) {
  const day = 1000 * 60 * 60 * 24
  if ((moment() - moment(eventTime)) / day < 5) {
    return true
  }
  return false
}

export function buildUserState(item) {
  let message = 'Unknown'
  if (
    (item.state === USER_STATE.DEFAULT && item.right === USER_RIGHT.CARD) ||
    item.state === USER_STATE.SIGNED_UP
  ) {
    message = 'Active'
  } else if (item.state === USER_STATE.ACTIVE_FAIL) {
    message = 'Activation Failed'
  } else if (
    item.state === USER_STATE.DEFAULT &&
    item.right !== USER_RIGHT.CARD
  ) {
    message = 'Enrolling'
  } else if (item.state === USER_STATE.UNCONFIRMED) {
    message = 'Unconfirmed'
  }
  return message
}

export function buildFilters(selectFilter = [], fieldName = 'operator') {
  const filters = []
  FILTER_FIELDS.forEach(key => {
    for (const filter of selectFilter) {
      if (filter[key]) {
        filters.push({
          name: key,
          value: filter[key],
          [fieldName]: filter.operator,
        })
      }
    }
  })

  return filters
}

export function buildEventViewNodeTypes(selectFilter = []) {
  if (!selectFilter.length) return selectFilter
  const filters = buildFilters(selectFilter, 'filterOperator')
  const resp = buildEventViewNodeType(filters)
  return resp
}

function buildEventViewNodeType(filters) {
  if (filters.length === 2) {
    return {
      expr1: { ...filters.pop(), __typename: 'EventViewFilter' },
      nodeOperator: 'AND',
      expr2: { ...filters.pop(), __typename: 'EventViewFilter' },
      __typename: 'EventViewBranch',
    }
  } else if (filters.length === 1) {
    return {
      expr1: { ...filters.pop(), __typename: 'EventViewFilter' },
      __typename: 'EventViewBranch',
    }
  }

  const expr2 = {
    expr1: { ...filters.pop(), __typename: 'EventViewFilter' },
    nodeOperator: 'AND',
    expr2: { ...filters.pop(), __typename: 'EventViewFilter' },
    __typename: 'EventViewBranch',
  }

  return {
    expr1: buildEventViewNodeType(filters),
    nodeOperator: 'AND',
    expr2,
    __typename: 'EventViewBranch',
  }
}

export function getFilterLable(operator) {
  if (operator === EVENT_OPERATION.EQUAL) {
    return 'is'
  } else if (operator === EVENT_OPERATION.NOT_EQUAL) {
    return 'is not'
  }
}

// build label event view filter ...
export function buildEventViewLabel(label) {
  /*
  return window.innerWidth > 600
    ? label
    : label.length < 20
    ? label
    : `${label.slice(0, 20)} ...`
    */

  return label
}

export function getFilterFromTree(tree, filters = []) {
  if (!tree) return []
  if (tree.__typename === 'EventViewFilter') {
    delete tree.__typename
    const operator = tree.filterOperator
    const label = `${capitalizeFirstLetter(tree.name)} ${getFilterLable(
      operator,
    )} ${tree.value}`
    delete tree.filterOperator
    const f = {
      label: buildEventViewLabel(label),
      operator,
      name: tree.name,
      [tree.name]: tree.value,
    }
    // build id for get names
    if (`${tree.name}`.match(/id/i)) {
      f.id = tree.value
    }

    filters.push(f)
    return filters
  }

  if (tree.__typename === 'EventViewBranch') {
    if (tree.expr1) {
      getFilterFromTree(tree.expr1, filters)
    }
    if (tree.expr2) {
      getFilterFromTree(tree.expr2, filters)
    }
  }

  return filters
}

export function buildHelperMenu() {
  let helpMenu = {
    text: '',
    link: '',
  }
  let curLocationHref = window.location.href
  if (
    curLocationHref.includes('config/doors') ||
    curLocationHref.includes('config/groups') ||
    curLocationHref.includes('config/door_tags')
  ) {
    helpMenu = {
      text: 'Door Configuration Guide',
      link: DOCS_LINK.doors,
    }
  } else if (
    curLocationHref.includes('users') ||
    curLocationHref.includes('user_tags') ||
    curLocationHref.includes('roles')
  ) {
    helpMenu = {
      text: 'User Management Help',
      link: DOCS_LINK.users,
    }
  } else if (curLocationHref.includes('rules')) {
    helpMenu = {
      text: 'Access Rules Help',
      link: DOCS_LINK.rules,
    }
  } else if (curLocationHref.includes('schedules')) {
    helpMenu = {
      text: 'Schedule Help',
      link: DOCS_LINK.schedules,
    }
  } else if (curLocationHref.includes('events/view')) {
    helpMenu = {
      text: 'Events Help',
      link: DOCS_LINK.events,
    }
  } else if (curLocationHref.includes('integrations')) {
    helpMenu = {
      text: 'Integrations Help',
      link: DOCS_LINK.integrations,
    }
  } else if (curLocationHref.includes('dashboard')) {
    helpMenu = {
      text: 'Dashboard Help',
      link: DOCS_LINK.dashboard,
    }
  } else if (curLocationHref.includes('mapdoors')) {
    helpMenu = {
      text: 'Doors Map Help',
      link: DOCS_LINK.doorsMap,
    }
  } else if (curLocationHref.includes('captured-videos')) {
    helpMenu = {
      text: 'Captured Video Help',
      link: DOCS_LINK.capturedVideo,
    }
  }

  return helpMenu
}

export function getMobileUI() {
  return window.innerWidth < 600
}

export const INDICATOR_TYPE = {
  LOCK: 'lock/unlock',
  OPEN: 'open',
}
// support the list doors on mobile screen
export function checkExistIndicatorItem(
  indicatorList = [],
  uuid,
  type = INDICATOR_TYPE.LOCK,
) {
  return indicatorList.find(item => item.uuid === uuid && item.type === type)
}

export function removeIndicatorItem(
  indicatorList = [],
  uuid,
  type = INDICATOR_TYPE.LOCK,
) {
  const index = indicatorList.findIndex(
    item => item.uuid === uuid && item.type === type,
  )
  if (index > -1) {
    indicatorList.splice(index, 1)
  }
}

export function proccessUserLoginError(err, dispatch, logout) {
  let message = 'Got error. Please login again.'
  if (err.graphQLErrors && err.graphQLErrors[0]) {
    message = err.graphQLErrors[0]['message']
  } else if (err.response && err.response.data && err.response.data.message) {
    message = err.response.data.message
  }
  localStorage.setItem('login_error', message)
  saveErrorToDebug(err)
  logout(dispatch)
}

export function getReaderLabel(reader) {
  let str = reader.idx
  if (reader.name) {
    str = `${reader.name} (${reader.idx})`
  }
  return str
}

export function getAppType() {
  return localStorage.getItem(KEYS.appType) || 'app.identivnox.com'
}

export const UserAvatarCacheKey = 'userAvatarCacheObject'
export const EventImageCaptureCacheKey = 'EventImageCaptureCacheKey'

export function getAvatarsStorageItem(item, avatarsStorage) {
  if (avatarsStorage && item && item.idx) {
    return avatarsStorage[item.idx]
  }
  return null
}

// check image expireTime
// avatarsStorageItem = {url,key,expires}
export async function cacheImages(images, avatarsStorageItem) {
  if (!Array.isArray(images) || images.length === 0) {
    return null
  }

  const second = Math.floor(Date.now() / 1000)
  for (const img of images) {
    const key = img['key']
    // img cached
    if (avatarsStorageItem) {
      if (
        avatarsStorageItem.key === key &&
        second - avatarsStorageItem.expires <= 700
      ) {
        return Promise.resolve(avatarsStorageItem, avatarsStorageItem.url)
      }
    }

    // get url from s3object
    return new Promise((resolve, reject) => {
      Auth.getInstance().getS3Client((err, S3Object) => {
        if (err) {
          return reject(err)
        }
        const params = {
          Bucket: img['bucket'],
          Key: key,
          Expires: 900, // default 15m (900s)
        }
        const url = S3Object.getSignedUrl('getObject', params)
        const imgUrl = { url, key, expires: Math.floor(Date.now() / 1000) } // 900s
        resolve(imgUrl, url)
      })
    })
  }
}

export function dispatchSettingToPage(dispatch, pageSetting) {
  if (!dispatch || !pageSetting) return
  dispatch({ type: 'addGlobalLayoutSetting', pageSetting })
}

export function isMobileSize(size) {
  return ['medium', 'small'].includes(size)
}

export function getTitle(size, name) {
  let title = `${name}s`

  if (isMobileSize(size)) {
    if (name === 'User Role') {
      title = 'Roles'
    } else if (name === 'Door Group') {
      title = 'Groups'
    } else if (name === 'Access Rule') {
      title = 'Access'
    }
  }

  return title
}

// options = {add,showDeletionsConfirmation,helpTag}
export function buildDispatchSettingToPage(props, title, options) {
  const { dispatch, refetch } = props
  const pageSetting = {
    title,
    refresh: refetch,
    ...options,
  }

  dispatchSettingToPage(dispatch, pageSetting)
}

export function getAlarmMutationSubscribeVariables(user, dealerToTenantId) {
  const variables = {}
  log(
    '---> getAlarmMutationSubscribeVariables --> dealerToTenantId: ',
    dealerToTenantId,
  )
  if (user) {
    if (user.tenantHost) {
      if (user.dealerId === 'global') {
        variables.dealerIdx = user.tenantId
      } else {
        variables.dealerIdx = user.dealerId
      }
      if (dealerToTenantId) {
        variables.sid = dealerToTenantId
      }
    } else {
      variables.sid = user.tenantId
    }
  }

  return variables
}

export async function buildUserLoginInfo() {
  let deviceTypeName = ''
  let deviceTypeVersion = ''
  if (deviceType === 'browser') {
    deviceTypeName = browserName
    deviceTypeVersion = fullBrowserVersion
  } else {
    deviceTypeName = mobileModel + ' ' + mobileVendor
  }
  let ipV4 = ''
  let ipV6 = ''
  try {
    ipV4 = await publicIp.v4()
    ipV6 = await publicIp.v6()
  } catch (error) {
    log.error('getIP error: ', error)
  }
  return {
    deviceType,
    deviceTypeName,
    deviceTypeVersion,
    getUA,
    osName,
    osVersion,
    ipV4,
    ipV6,
  }
}
