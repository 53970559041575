import alarm from './alarm'
import { combineReducers } from 'redux'
import globalLayoutSetting from './globalLayoutSetting'
import nav from './nav'
import session from './session'
import setting from './setting'
import theme from './theme'
import user from './user'

export default combineReducers({
  nav,
  session,
  user,
  setting,
  alarm,
  globalLayoutSetting,
  theme,
})
